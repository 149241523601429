import { Image, StyleSheet, Pressable, View, TextInput, ScrollView, Alert } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect, useState } from "react";
import { Text } from '../components/Text';
import { useTypedDispatch, useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { Link } from "@react-navigation/native"
import Checkbox from 'expo-checkbox';
import * as ImagePicker from 'expo-image-picker';
import { RESTUserUpdate, RESTUserUpdatePassword, RESTUserUploadImage } from "../libs/UsersQueries";
import { storePopulateUser } from "../store/modules/Authentication";
import { uploadAsync, FileSystemUploadType } from 'expo-file-system';

interface Props extends NativeStackScreenProps<RootStackParamList, 'ProfileEdit'> { }

export const ProfileEdit: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  const dispatch = useTypedDispatch();
  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, token, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    entity === null && navigation.navigate("Home");
  }, [_props, isFocused])

  const [firstName, setFirstName] = useState<string>(entity?.firstName || '')
  const [lastName, setLastName] = useState<string>(entity?.lastName || '')
  const [email, setEmail] = useState<string>(entity?.email || '')
  const [sexeMale, setSexeMale] = useState<boolean>(entity?.sexe === 'homme' || false);
  const [sexeFemale, setSexeFemale] = useState<boolean>(entity?.sexe === 'femme' || false);
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const updateProfile = () => {
    RESTUserUpdate(token, {
      firstName,
      lastName,
      email,
      sexe: sexeMale ? 'homme' : 'femme',
    }).then((user) => {
      if (user !== undefined) {
        dispatch(storePopulateUser(user))
        navigation.navigate('Profile')
      }
    })

    if (password !== '' && confirmPassword === password) {
      RESTUserUpdatePassword(token, { password }).then((user) => {
        if (user !== undefined) {
          dispatch(storePopulateUser(user))
          navigation.navigate('Profile')
        }
      })
    }
  }

  const changeSexe = (choosen: string) => {
    if (choosen === 'femme') {
      setSexeFemale(true)
      setSexeMale(false)
    }

    if (choosen === 'homme') {
      setSexeFemale(false)
      setSexeMale(true)
    }
  }

  return (
    <ScrollView style={styles.container} nestedScrollEnabled={true} showsVerticalScrollIndicator={false}>
      <View style={styles.header}>
        <Image
          style={styles.profilepicture}
          source={{ uri: entity?.photoProfile || "https://source.unsplash.com/user/c_v_r/100x100" }}
        />
        <Pressable onPress={() => {
          ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true, aspect: [4, 3], quality: 1,
            base64: true, exif: true
          }).then((result) => {
            if (result.canceled) return
            if (result.assets![0].base64) {
              const imageUri = `data:image/png;base64,${result.assets![0].base64}`;
              RESTUserUploadImage(token, imageUri, 'photoProfile')
                .then((user) => user !== undefined ? dispatch(storePopulateUser(user)) : Alert.alert('Une erreur s\'est produite lors du chargement de l\'image'))
                .catch((e) => console.log(e))
            } else {
              Alert.alert('Erreur lors du chargement de l\'image')
            }
          })
        }}>
          <Text style={styles.textFullname}>MODIFIER MA PHOTO DE PROFIL</Text>
        </Pressable>
      </View>
      <View style={styles.summaries}>
        <Text style={styles.summariesTitle}>INFORMATIONS PERSONNELLES</Text>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>NOM</Text>
          <TextInput value={lastName} style={styles.summariesDataData} onChangeText={text => setLastName(text)} />
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>PRÉNOM</Text>
          <TextInput value={firstName} style={styles.summariesDataData} onChangeText={text => setFirstName(text)} />
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>EMAIL</Text>
          <TextInput value={email} style={styles.summariesDataData} onChangeText={text => setEmail(text)} />
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>SEXE</Text>
          <View style={styles.sectionsex}>
            <View style={styles.sectioncheck}>
              <Checkbox
                value={sexeMale}
                onValueChange={() => changeSexe('homme')}
                color={sexeMale ? 'black' : undefined}
              />
              <Text>Homme</Text>
            </View>
            <View style={styles.sectioncheck}>
              <Checkbox
                value={sexeFemale}
                onValueChange={() => changeSexe('femme')}
                color={sexeFemale ? 'black' : undefined}
              />
              <Text>Femme</Text>
            </View>
          </View>
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>MOT DE PASSE</Text>
          <TextInput value={password} secureTextEntry style={styles.summariesDataData} onChangeText={text => setPassword(text)} />
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>CONFIRMER VOTRE MOT DE PASSE</Text>
          <TextInput value={confirmPassword} secureTextEntry style={styles.summariesDataData} onChangeText={text => setConfirmPassword(text)} />
        </View>
        <View style={styles.btn__container}>
          <Pressable onPress={() => updateProfile()}>
            <Text style={styles.SaveBtn}>MODIFIER MON PROFIL</Text>
          </Pressable>
        </View>

      </View>
    </ScrollView>

  )
}

const styles = StyleSheet.create({
  sectionsex: {
    marginTop: 10,
    marginBottom: 10,
    gap: 15,
    display: 'flex',
    flexDirection: 'row'
  },
  sectioncheck: {
    gap: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  sexe: {
    margin: 0,
    padding: 0,

  },
  btn__container: {
    marginTop: 20,
    marginBottom: 0

  },
  SaveBtn: {
    color: '#fff',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center',
    padding: 15,
    backgroundColor: 'black'
  },
  img__icon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    width: 32,
    height: 32,

  },
  link_title: {
    width: '100%',
    height: '100%',
    position: 'relative',


  },
  board: {
    display: 'flex',
    flexDirection: 'column'
  },
  title__board: {
    color: 'white',
    fontWeight: 'bold'
  },
  content__board: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 15,
    marginTop: 25,

    marginBottom: 15,
    width: '100%'
  },
  mood__board: {
    flex: 1,
    position: 'relative',
    padding: 15,
    borderRadius: 5,
    height: 100,
    backgroundColor: '#E584BB',
    textAlign: 'left',
    color: 'white'
  },
  abonnement__board: {
    flex: 1,
    padding: 15,
    position: 'relative',
    borderRadius: 5,
    height: 100,
    backgroundColor: '#C8639C',
    textAlign: 'left',
    color: 'white'
  },
  stats: {
    padding: 15,
    borderRadius: 5,
    height: 125,

    width: '100%',
    position: 'relative',
    backgroundColor: '#D66DA8',
    textAlign: 'left',
    color: 'white'
  },
  container: {
    height: '100%',
    width: '100%',
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    alignItems: 'center',
    paddingBottom: 20,
    gap: 20,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1
  },
  profilepicture: {
    width: 70,
    height: 70,
    borderRadius: 50,
    borderColor: '#fff',
    borderWidth: 2
  },
  headertexts: {
    display: 'flex',
    flexDirection: 'column'
  },
  textHello: {
    color: '#000',
    fontWeight: '300',
    fontSize: 24
  },
  textFullname: {
    color: '#000',
    fontWeight: '400',
    fontSize: 12
  },
  summaries: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0
  },
  summariesTitle: {
    color: '#000',
    fontSize: 14,
    paddingTop: 20,
    fontWeight: "800"
  },
  summariesData: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  summariesDataTitle: {
    color: '#000',
    fontWeight: '400',
    fontSize: 12,
    marginBottom: 0
  },
  summariesDataData: {
    marginTop: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    color: '#000',
    fontWeight: '500',
    fontSize: 15
  }
});
