import { Alert, Keyboard, Pressable, StyleSheet, TextInput, TouchableWithoutFeedback, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect, useState } from "react";
import { Text } from '../components/Text';
import DropDownPicker from "react-native-dropdown-picker";
import { useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { NODEMAILERSendMail } from "../libs/MailQueries";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Contact'> { }

export const Contact: FC<Props> = (_props: Props) => {
    const { navigation } = _props;
    const isFocused = useIsFocused();

    let authentication = useTypedSelector((state) => state.authentication);
    const { authenticated, token, entity } = authentication;

    useEffect(() => {
        !authenticated && navigation.navigate("Authentication");
    }, [_props, isFocused])

    const [openReasonDropdown, setOpenReasonDropdown] = useState(false);
    const [valueReasonDropdown, setValueReasonDropdown] = useState('');
    const [itemsReasonDropdown, setItemsReasonDropdown] = useState([
        { label: 'Erreur technique', value: 'Erreur technique' },
        { label: 'Problème avec mon abonnement', value: 'Problème avec mon abonnement' },
        { label: 'Coaching', value: 'Coaching' },
        { label: 'Autre', value: 'Autre' },
    ]);

    const [valueMessage, setValueMessage] = useState<string>('');

    const sendContactMessage = () => {
        if (valueReasonDropdown === '') Alert.alert('Merci de renseigner la raison du contact')
        if (valueMessage === '') Alert.alert('Merci de renseigner un message')
        if (entity && entity.email && valueReasonDropdown !== '' && valueMessage !== '') {
            NODEMAILERSendMail(token, { email: entity.email, reason: valueReasonDropdown, message: valueMessage })
                .then(
                    (response) => {
                        if (response !== undefined) {
                            setValueMessage('');
                            setValueReasonDropdown('')
                            Alert.alert('Message envoyé')
                        } else {
                            Alert.alert('Une erreur c\'est produit')
                        }
                    }
                )
        }
    }

    return (

        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.title}>CONTACT &{"\n"}SUPPORT</Text>
                    <Text style={styles.text}>Pour toutes questions/problèmes,{"\n"}n'hésitez pas à nous contacter sur :{"\n"}support@belly-sculpting.com{"\n"}ou via le formulaire ci-dessous</Text>
                </View>
                <View style={styles.form}>
                    <DropDownPicker
                        open={openReasonDropdown}
                        value={valueReasonDropdown}
                        items={itemsReasonDropdown}
                        setOpen={setOpenReasonDropdown}
                        setValue={setValueReasonDropdown}
                        setItems={setItemsReasonDropdown}
                        placeholder={'J\'ai un problème'}
                        style={{
                            borderRadius: 5
                        }}
                    />
                    <View style={styles.textAreaContainer} >
                        <TextInput
                            style={styles.textArea}
                            underlineColorAndroid="transparent"
                            placeholder="Écrivez votre message ..."
                            placeholderTextColor="#000"
                            numberOfLines={10}
                            multiline={true}
                            onChangeText={text => setValueMessage(text)}
                            value={valueMessage}
                        />
                    </View>
                </View>
                <Pressable style={styles.sendButton} onPress={() => sendContactMessage()}>
                    <Text style={{ color: '#fff' }}>ENVOYER</Text>
                </Pressable>
            </View>
        </TouchableWithoutFeedback>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%'
    },
    header: {
        width: '100%',
        paddingTop: 60
    },
    title: {
        fontSize: 24,
        fontWeight: '500',
        textAlign: 'center'
    },
    text: {
        fontSize: 15,
        fontWeight: '500',
        textAlign: 'center',
        marginTop: 60,
        lineHeight: 30,
        color: '#767676'
    },
    form: {
        width: '100%',
        paddingTop: 60,
        paddingLeft: 30,
        paddingRight: 30,
    },
    textAreaContainer: {
        borderColor: '#000',
        borderWidth: 1,
        padding: 5,
        marginTop: 20,
        borderRadius: 5,
        backgroundColor: '#fff'
    },
    textArea: {
        height: 150,
        justifyContent: "flex-start",
        padding: 5
    },
    sendButton: {
        width: '90%',
        position: 'absolute',
        bottom: '3%',
        left: '5%',
        paddingBottom: 15,
        paddingTop: 15,
        backgroundColor: '#000',
        color: '#fff',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});