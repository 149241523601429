import { FC, useCallback, useEffect } from "react";
import { Linking, Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";

interface Props extends NativeStackScreenProps<RootStackParamList, 'CoursSingle'> { }

export const CoursSingle: FC<Props> = (_props: Props) => {
  const { route, navigation } = _props;
  const { cours } = route.params;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    cours === null && navigation.navigate("Home");
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
  }, [_props, isFocused])

  const studioUrl = 'https://belly-sculpting.com/cours-studio';

  const handleReservationBtn = useCallback(async () => {
    const supported = await Linking.canOpenURL(studioUrl);
    if (supported) {
      await Linking.openURL(studioUrl);
    }
  }, [studioUrl]);

  return (
    <ScrollView contentContainerStyle={styles.container} showsHorizontalScrollIndicator={false}>
      <View style={styles.description_content}>
        <Text style={styles.descriptionText}>{cours?.name}</Text>
        <Text style={styles.price}>{`${cours?.price}€`}</Text>
      </View>
      <View style={styles.content}>
        <Text style={styles.title}>{cours?.date}</Text>
        <Text style={styles.title}>{cours?.description}</Text>
        <View style={styles.address}>
          <Text style={styles.titlelight}>{cours?.lieu}</Text>
          <Text style={styles.titlelight}>{cours?.address}</Text>
          <Pressable onPress={handleReservationBtn} >
            <Text style={styles.btn_book}>RÉSERVER</Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  btn_book: {
    marginTop: 20,
    backgroundColor: 'black',
    color: 'white',
    fontWeight: 'bold',
    padding: 20,

    width: '100%',

    textAlign: 'center'
  },
  titlelight: {
    textAlign: 'center'
  },
  address: {
    marginTop: 20,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
    width: '100%',
    TextAlign: 'center'
  },
  price: {
    color: 'white',
    borderWidth: 1,
    borderColor: 'white',
    padding: 7.5,
    borderRadius: 10
  },
  description_content: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#252525',
    gap: 40,
    paddingTop: 20,
    paddingBottom: 20,
  },
  layer: {
    marginBottom: 30
  },
  date__content: {
    flexDirection: 'row'
  },
  rose: {
    backgroundColor: '#E584BB'
  },
  date: {
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 20,
    color: 'black',
    display: 'flex'
  },
  primary: {
    fontSize: 30,
    color: 'white',
  },
  secondary: {
    fontSize: 12.5,
    color: 'white',
  },
  title: {
    color: 'black',
    fontWeight: '700',
    fontSize: 15
  },
  background_cours: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 'auto',
    backgroundColor: '#252525'
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',


  },
  description: {
    backgroundColor: '#252525',
    paddingTop: 20,
    paddingBottom: 20,
  },
  descriptionText: {
    color: '#fff',
    fontWeight: '300',
    fontSize: 20,
    lineHeight: 30,
    textAlign: 'center'
  },
  content: {
    paddingTop: 30,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    height: '100%',
    flexDirection: 'column',

    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    gap: 10
  },
  video: {
    width: '50%'
  }
});
