
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from '@expo/vector-icons/Ionicons';
import { ICategory, ICours, IExtentedVideo } from './libs/Models';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { GRAPHQLGetVideos } from './libs/VideosQueries';
import { useTypedDispatch, useTypedSelector } from './store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback, useEffect, useState } from 'react';
import { addVideos } from './store/modules/Videos';
import { GRAPHQLGetCategories } from './libs/CategoriesQueries';
import { addCategories } from './store/modules/Categories';
import { Stats } from './pages/Stats'
import { ProfileEdit } from './pages/ProfileEdit'
import { Home } from './pages/Home';
import { Profile } from './pages/Profile';
import { Categories } from './pages/Categories';
import { Category } from './pages/Category';
import { Video } from './pages/Video';
import { HeaderBarTitle } from './components/HeaderBarTitle';
import { HeaderBarRight } from './components/HeaderBarRight';
import { HeaderBarLeft } from './components/HeaderBarLeft';
import { Favorites } from './pages/Favorites';
import { Contact } from './pages/Contact';
import { Authentication } from './pages/Authentication';
import { Planning } from './pages/Planning';
import { Abonnement } from './pages/Abonnement'
import { RESTAuthenticationGetUser } from './libs/AuthenticationQueries';
import { storePopulateToken, storePopulateUser, storeSetAuthenticated } from './store/modules/Authentication';
import { Mood } from './pages/Mood';
import { Cours } from './pages/Cours';
import { CoursSingle } from './pages/CoursSingle';
import { GRAPHQLGetCours } from './libs/CoursQueries';
import { addCours } from './store/modules/Cours';

export type RootStackParamList = {
  Authentication: undefined;
  Abonnement:undefined;
  Home: undefined;
  Favorites: undefined;
  Categories: undefined;
  Planning: undefined;
  Mood: undefined;
  Stats:undefined;
  Category: { category?: ICategory };
  Video: { video?: IExtentedVideo };
  Profile: undefined;
  ProfileEdit:undefined;
  Contact: undefined;
  Cours: undefined;
  CoursSingle: { cours?: ICours }; 
};

const Stack = createBottomTabNavigator<RootStackParamList>();

const generateTabBarIcon = (route: any, color: string) => {
  let iconName: any = '';

  switch (route.name) {
    case 'Home':
      iconName = 'home-outline';
      break;
    case 'Favorites':
      iconName = 'heart-outline';
      break;
    case 'Categories':
      iconName = 'play-outline';
      break;
    case 'Planning':
      iconName = 'calendar-outline';
      break;
    case 'Contact':
      iconName = 'mail-outline';
      break;
    case 'Cours':
      iconName = 'barbell-outline';
      break;
    default:
      iconName = 'home-outline';
      break;
  }

  return <Ionicons name={iconName} color={color} size={23} />;
};

SplashScreen.preventAutoHideAsync();

export default function Native() {
  const [appIsReady, setAppIsReady] = useState<boolean>(false);
  const dispatch = useTypedDispatch();

  let authentication = useTypedSelector((state) => state.authentication);
  
  const { authenticated } = authentication;
  
  useEffect(() => {
    const prepare = async () => {
      try {
        await Font.loadAsync({
          HelveticaNeue: require('./assets/fonts/HelveticaNeue-01.ttf'),
        });

        const categories: ICategory[] = await GRAPHQLGetCategories();
        const cours: ICours[] = await GRAPHQLGetCours();
        let videos: IExtentedVideo[] = await GRAPHQLGetVideos();
        
        dispatch(addCategories(categories))
        dispatch(addCours(cours))
        dispatch(addVideos(videos))
        const authenticationToken = await AsyncStorage.getItem('@authentication_token')
        if(authenticationToken !== null) {
          RESTAuthenticationGetUser(authenticationToken)
          .then((user) => {
            if(user != undefined) {
              dispatch(storePopulateToken(authenticationToken));
              dispatch(storeSetAuthenticated(true));
              dispatch(storePopulateUser(user));
            }
          })
          .catch((e) => console.log(e))
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }

    prepare();
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) await SplashScreen.hideAsync();
  }, [appIsReady]);

  if (!appIsReady) return null;

  return (
    <NavigationContainer onReady={onLayoutRootView} >
      <StatusBar style='auto' />
      <Stack.Navigator backBehavior='history' screenOptions={({ route }) => ({
        headerStyle: { height: 100 },
        headerShadowVisible: false,
        tabBarIcon: ({ color }) => generateTabBarIcon(route, color),
        tabBarStyle: ['Authentication'].includes(route.name) ? { display: 'none' } : { height: 70, backgroundColor: '#262422', borderTopWidth: 0 },
        tabBarShowLabel: false,
        tabBarInactiveTintColor: authenticated ? '#fff' : 'gray',
        tabBarActiveTintColor: '#E584BB',
        tabBarButton: [
          'Authentication',
          'Profile',
          'ProfileEdit',
          'Abonnement',
          'Mood',
          'Stats',
          'Category',
          'Video',
          'CoursSingle'
        ].includes(route.name)
          ? () => {
            return null;
          }
          : undefined,
      })}>
        <Stack.Screen
          name='Home'
          component={Home}
          options={{
            title: 'Home',
            headerTitle: (props) => <HeaderBarTitle title={''} icon={require('./assets/icon.png')} {...props} />,
            headerLeft: (props) => <></>,
            headerRight: (props) => authenticated && <HeaderBarRight videoLike={false} liked={false} {...props} />,
            headerTransparent: true,
          }}
        />
        <Stack.Screen
          name='Authentication'
          component={Authentication}
          options={{
            title: 'Authentication',
            headerTitle: (props) => <HeaderBarTitle title={''} icon={require('./assets/icon.png')} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTransparent: true,
          }}
        />
        <Stack.Screen
          name='Favorites'
          component={Favorites}
          options={{
            title: 'Favoris',
            headerTitle: (props) => <HeaderBarTitle title={'MES FAVORIS'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
        <Stack.Screen
          name='Profile'
          component={Profile}
          options={{
            title: 'Profile',
            headerTitle: (props) => <HeaderBarTitle title={'MON PROFIL'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
      <Stack.Screen
          name='ProfileEdit'
          component={ProfileEdit}
          options={{
            title: 'ProfileEdit',
            headerTitle: (props) => <HeaderBarTitle title={'EDITER MON PROFIL'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
          <Stack.Screen
          name='Stats'
          component={Stats}
          options={{
            title: 'Stats',
            headerTitle: (props) => <HeaderBarTitle title={'MES STATS'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
             <Stack.Screen
          name='Mood'
          component={Mood}
          options={{
            title: 'Stats',
            headerTitle: (props) => <HeaderBarTitle title={'MOOD'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
          <Stack.Screen
          name='Abonnement'
          component={Abonnement}
          options={{
            title: 'Stats',
            headerTitle: (props) => <HeaderBarTitle title={'MON ABONNEMENT'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
        <Stack.Screen
          name='Categories'
          component={Categories}
          options={{
            title: 'Categories',
            headerTitle: (props) => <HeaderBarTitle title={'VIDEOS'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
        <Stack.Screen
          name='Planning'
          component={Planning}
          options={{
            title: 'Planning',
            headerTitle: (props) => <HeaderBarTitle title={'TON PLANNING'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerTransparent: true,
          }}
        />
        <Stack.Screen
          name='Category'
          component={Category}
          options={{
            title: 'Category',
            headerTitle: (props) => <HeaderBarTitle title={'CATEGORY'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
        <Stack.Screen
          name='Video'
          component={Video}
          options={{
            title: 'Video',
            headerTitle: (props) => <></>,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: {},
            headerTransparent: true,
          }}
        />
        <Stack.Screen
          name='Cours'
          component={Cours}
          options={{
            title: 'Cours',
            headerTitle: (props) => <HeaderBarTitle title={'COURS & ÉVÊNEMENTS'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
         <Stack.Screen
          name='CoursSingle'
          component={CoursSingle}
          options={{
            title: 'CoursSingle',
            headerTitle: (props) => <HeaderBarTitle title={'COURS & ÉVÊNEMENTS'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
        <Stack.Screen
          name='Contact'
          component={Contact}
          options={{
            title: 'Contact',
            headerTitle: (props) => <HeaderBarTitle title={'CONTACT'} {...props} />,
            headerLeft: (props) => <HeaderBarLeft {...props} />,
            headerRight: (props) => <></>,
            headerTitleAlign: 'center',
            headerStyle: { backgroundColor: '#252525' },
            headerTransparent: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}