import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ICours } from "../../libs/Models";

interface CoursState {
  entities: ICours[]
}

const initialState: CoursState = {
  entities: []
};

const CoursSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCours(state, action: PayloadAction<ICours[]>) {
      state.entities = action.payload
    },
  },
});

export const { addCours } = CoursSlice.actions;

export default CoursSlice.reducer;