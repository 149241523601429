import { Link, useIsFocused } from "@react-navigation/native";
import { ImageBackground, Linking, Pressable, SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { CategoryThumb } from "../components/CategoryThumb";
import { VideosThumb } from "../components/VideosThumb";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC } from "react";
import { Text } from "../components/Text";
import { ICategory } from "../libs/Models";
import { useTypedSelector } from "../store";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Home'> { }

const generateTrendingCategoryName = (name: string): { title1: string, title2: string } => {
  switch (name) {
    case 'STOMACH VACUUM / HYPPOPRESSIFS':
      return { title1: 'STOMACH', title2: 'VACUUM' }
    case 'ROUTINES EXPRESS':
      return { title1: 'ROUTINE', title2: 'EXPRESS' }
    case 'FULL BODY INTENSITY':
      return { title1: 'FULL BODY', title2: 'INTENSITY' }
    case 'HOT BOOTY WORKOUT':
      return { title1: 'HOT BOOTY', title2: 'WORKOUT' }
    default:
      return { title1: 'CATEGORY', title2: 'NAME' }
  }
}

const generateTrendingCategoryImage = (name: string) => {
  switch (name) {
    case 'STOMACH VACUUM / HYPPOPRESSIFS':
      return require('../assets/category-1-background.png')
    case 'ROUTINES EXPRESS':
      return require('../assets/category-2-background.png')
    case 'FULL BODY INTENSITY':
      return require('../assets/category-3-background.png')
    case 'HOT BOOTY WORKOUT':
      return require('../assets/category-4-background.png')
    default:
      return require('../assets/category-1-background.png')
  }
}

export const Home: FC<Props> = (_props: Props) => {
  let authentication = useTypedSelector((state) => state.authentication);
  let categories = useTypedSelector((state) => state.categories.entities);

  const { authenticated, entity } = authentication;

  return (
    <ScrollView contentContainerStyle={styles.container} nestedScrollEnabled={true} showsVerticalScrollIndicator={false}>
      <SafeAreaView style={styles.view}>
        <ScrollView contentContainerStyle={styles.container} nestedScrollEnabled={true} showsVerticalScrollIndicator={false}>
          <View style={headerStyles.container}>
            {(authenticated && entity?.isSubscribed) ? (
              <ImageBackground source={require('../assets/home-background.png')} style={headerStyles.background} />
            ) : (
              <ImageBackground source={require('../assets/home-background-2.jpg')} style={headerStyles.background} />
            )}
            <View style={headerStyles.content}>
              {authenticated && entity?.isSubscribed ? (
                <Text style={headerStyles.titlethin}>PILATES & FITNESS EN CONSCIENCE</Text>
              ) : (
                <Text style={headerStyles.titlethin}>PROGRAMME BELLY SCULPTING</Text>
              )}
              {authenticated && entity?.isSubscribed ? (
                <Text style={headerStyles.titlebold}>LA MÉTHODE #NOBULLSHIT{"\n"}<Text style={headerStyles.titlemedium}>À CONSOMMER SANS MODÉRATION</Text></Text>
              ) : (
                <Text style={headerStyles.titlebold}>ABONNEZ-VOUS POUR ACCÉDER À TOUS LES CONTENUS</Text>
              )}
              {authenticated && entity?.isSubscribed ? (
                <Pressable>
                  <Text style={headerStyles.link}>RENDEZ-VOUS SUR BELLY-SCULPTING.COM</Text>
                </Pressable>
              ) : (
                <Pressable>
                  <Text style={headerStyles.link}>RENDEZ-VOUS SUR BELLY-SCULPTING.COM</Text>
                </Pressable>
              )}
            </View>

          </View>
          <View style={categoriestyles.container}>
            <View style={categoriestyles.header}>
              <Text style={categoriestyles.title}>LES ESSENTIELS</Text>
              <Link style={categoriestyles.seemorelink} to={{ screen: 'Categories' }}>
                {authenticated && (
                  <Text style={categoriestyles.seemore}>VOIR TOUT</Text>
                )}
              </Link>
            </View>
            <View style={categoriestyles.content}>
              {categories.map((category: ICategory) => {
                if (['STOMACH VACUUM / HYPPOPRESSIFS', 'ROUTINES EXPRESS', 'FULL BODY INTENSITY', 'HOT BOOTY WORKOUT'].includes(category.name)) {
                  const titles = generateTrendingCategoryName(category.name)
                  return (
                    <CategoryThumb
                      key={category.id}
                      disabled={(!authenticated || ( entity ? !entity?.isSubscribed : false))}
                      category={category}
                      image={generateTrendingCategoryImage(category.name)}
                      title1={titles.title1}
                      title2={titles.title2}
                    />
                  )
                }
              })}
            </View>
          </View>
          <View style={bannerStyles.container}>
            <ImageBackground source={require('../assets/courses-background.png')} style={bannerStyles.background}>
              <Text style={bannerStyles.title}>MASTERCLASS</Text>
              <Text style={bannerStyles.name}>DÉCOUVREZ NOS COURS{"\n"}& ÉVÊNEMENTS </Text>
              {/* <Text style={bannerStyles.date}>27.05.2023</Text> */}
              <Link style={styles.link_title} to={{ screen: 'Cours' }} >
                <Text style={styles.link_btn}>DÉCOUVRIR</Text>
              </Link>
            </ImageBackground>
          </View>
          {categories.map((category: ICategory) =>
            <VideosThumb key={category.id} disabled={(!authenticated || ( entity ? !entity?.isSubscribed : false))} category={category} title={category.name} />
          )}
        </ScrollView>
      </SafeAreaView>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  link_title: {
    padding: 5,
    backgroundColor: 'white',
    color: 'black',

    display: 'flex',
    width: 100,
    textAlign: 'center',
    fontWeight: '800'
  },
  link_btn: {



  },
  view: {
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    paddingBottom: 25,
    height: 'auto'
  },
});

const headerStyles = StyleSheet.create({
  container: {
    height: 300,
    position: 'relative',
    backgroundColor: 'black',
  },
  background: {

    flex: 1,
    resizeMode: 'cover',
    opacity: 0.8
  },
  content: {
    position: 'absolute',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  titlethin: {
    fontWeight: '300',
    color: 'white',
    fontSize: 15,
    marginBottom: 10
  },
  titlebold: {
    fontWeight: '800',
    color: 'white',
    fontSize: 22,
    lineHeight: 22,
    marginBottom: 20
  },
  titlemedium: {
    fontWeight: '300',
    color: 'white',
    fontSize: 20,
    lineHeight: 20
  },
  link: {
    padding: 10,
    backgroundColor: '#E584BB',
    color: 'white',
    fontWeight: '800',
    width: 200,
    display: "flex"
  }
})

const categoriestyles = StyleSheet.create({
  container: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 25
  },
  title: {
    fontSize: 15,
    fontWeight: '700'
  },
  seemorelink: {
    borderBottomColor: '#000',
    borderBottomWidth: 0.5,
  },
  seemore: {
    fontSize: 8,
    fontWeight: '400',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  }
})

const bannerStyles = StyleSheet.create({
  container: {
    height: 300,
  },
  background: {
    flex: 1,
    resizeMode: 'cover',
    paddingHorizontal: 23,
    paddingVertical: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16
  },
  title: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '300'
  },
  name: {
    color: '#fff',
    fontSize: 22,
    fontWeight: '700'
  },
  date: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '400'
  },
})

const videosStyles = StyleSheet.create({
  container: {}
})