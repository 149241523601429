import { Link } from "@react-navigation/native"
import { ScrollView, StyleSheet, View } from "react-native"
import { ICategory, IExtentedVideo } from "../libs/Models"
import { useTypedSelector } from "../store"
import { selectVideosByCategory } from "../store/modules/Videos"
import { Text } from "./Text"
import { VideoThumb } from "./VideoThumb"

interface IProps {
  category: ICategory;
  title: string;
  disabled: boolean;
}

export const VideosThumb = ({ category, title, disabled }: IProps) => {
  let videos = useTypedSelector((state) => selectVideosByCategory(state.videos, category.id));
  videos = videos.slice(0, 4);

  return (
    <View style={styles.container}>
      <Link to={disabled ? { screen: 'Authentication' } : { screen: 'Category', params: { category: category } }}>
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
          {!disabled && (
            <Text style={styles.seemore}>VOIR TOUT</Text>
          )}
        </View>
      </Link>
      <ScrollView contentContainerStyle={styles.content} horizontal={true} showsHorizontalScrollIndicator={false}>
        {videos.map((video: IExtentedVideo) =>
          <VideoThumb key={video.id} disabled={disabled} video={video} category={category.name} />
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15
  },
  title: {
    fontSize: 15,
    fontWeight: '700'
  },
  seemore: {
    fontSize: 8,
    fontWeight: '400',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 15,
    paddingLeft: 15,
    paddingRight: 15,
  }
})