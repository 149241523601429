import { View, StyleSheet, ImageBackground, TextInput, TouchableWithoutFeedback, Keyboard, Pressable, Image, Alert, Linking } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '../App';
import { FC, useCallback, useEffect, useState } from 'react';
import { Text } from '../components/Text'
import { useTypedDispatch, useTypedSelector } from '../store';
import { RESTAuthenticationGetUser, RESTAuthenticationLogin, RESTAuthenticationRegister } from '../libs/AuthenticationQueries';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { storePopulateToken, storePopulateUser, storeSetAuthenticated } from '../store/modules/Authentication';
import { useIsFocused } from '@react-navigation/native';
import Checkbox from 'expo-checkbox';
import * as ImagePicker from 'expo-image-picker';
import DropDownPicker from 'react-native-dropdown-picker';
import { RESTUserUpdate, RESTUserUploadImage } from '../libs/UsersQueries';

interface Props extends NativeStackScreenProps<RootStackParamList, 'Authentication'> { }

type LoginState = {
  email: string,
  password: string
}

const initialLoginState = {
  email: '',
  password: ''
}

type RegisterState = {
  firstname: string,
  lastname: string,
  sexe: string,
  email: string,
  password: string,
  passwordConfirm: string,
  weight: number,
  waistsize: number,
  reasonOfSubscription: string
}

const initialRegisterState = {
  firstname: '',
  lastname: '',
  sexe: '',
  email: '',
  password: '',
  passwordConfirm: '',
  weight: 0,
  waistsize: 0,
  reasonOfSubscription: ''
}

export const Authentication: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  const dispatch = useTypedDispatch();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, token, entity } = authentication;

  useEffect(() => {
    authenticated && navigation.navigate('Home')
  }, [_props, isFocused])

  const [choicesModal, setChoicesModal] = useState<boolean>(true);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [registerModal_1, setRegisterModal_1] = useState<boolean>(false);
  const [registerModal_2, setRegisterModal_2] = useState<boolean>(false);
  const [registerModal_3, setRegisterModal_3] = useState<boolean>(false);
  const [registerModal_4, setRegisterModal_4] = useState<boolean>(false);
  const [registerModal_5, setRegisterModal_5] = useState<boolean>(false);
  const [loginValues, setLoginValues] = useState<LoginState>(initialLoginState);
  const [registerValues, setRegisterValues] = useState<RegisterState>(initialRegisterState);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
  const [isCGVAcceptedChecked, setIsCGVAcceptedChecked] = useState(false);
  const [photoProfile, setPhotoProfile] = useState<string | null>(null)

  const [openSexeDropdown, setOpenSexeDropdown] = useState(false);
  const [valueSexeDropdown, setValueSexeDropdown] = useState('');
  const [itemsSexeDropdown, setItemsSexeDropdown] = useState([
    { label: 'Homme', value: 'homme' },
    { label: 'Femme', value: 'femme' }
  ]);

  const submitLogin = () => {
    RESTAuthenticationLogin(loginValues)
      .then(response => response != undefined ? response.token : undefined)
      .then((token) => {
        if (token != undefined) {
          RESTAuthenticationGetUser(token)
            .then((user) => {
              if (user != undefined) {
                dispatch(storeSetAuthenticated(true))
                dispatch(storePopulateUser(user))
                AsyncStorage.setItem('@authentication_token', token)
                  .catch((e) => console.log(e))
                navigation.navigate('Home')
              }
            })
            .catch((e) => Alert.alert('Connexion', 'Identifiants incorrects'))
        }
      })
      .catch((e) => Alert.alert('Connexion', 'Identifiants incorrects'))
  }

  const signupUser = () => {
    if (registerValues.email === '' || registerValues.firstname === '' || valueSexeDropdown === '' || registerValues.lastname === '' || registerValues.password === '' || registerValues.passwordConfirm === '') {
      Alert.alert('Merci de remplir tous les champs')
    }
    else if (registerValues.password !== registerValues.passwordConfirm) {
      Alert.alert('Les mots de passe ne sont pas identiques')
    }
    else if (!isCGVAcceptedChecked) {
      Alert.alert('Vous devez accepter les CGV pour continuer')
    }
    else {
      RESTAuthenticationRegister({
        email: registerValues.email,
        password: registerValues.password,
        sexe: valueSexeDropdown,
        firstName: registerValues.firstname,
        lastName: registerValues.lastname,
        newsletterOptin: isNewsletterChecked
      })
        .then(response => response != undefined ? response.token : undefined)
        .then((token) => {
          if (token != undefined) {
            dispatch(storePopulateToken(token))
            RESTAuthenticationGetUser(token)
              .then((user) => {
                if (user != undefined) {
                  dispatch(storeSetAuthenticated(true))
                  dispatch(storePopulateUser(user))
                  AsyncStorage.setItem('@authentication_token', token)
                    .catch((e) => console.log(e))
                  setLoginModal(false);
                  setRegisterModal(false);
                  setRegisterModal_1(true);
                }
              })
              .catch((e) => console.log(e))
          }
        })
        .catch((e) => console.log(e))
    }
  }

  const updateUserInfos = () => {
    RESTUserUpdate(token, {
      startWeight: registerValues.weight,
      startMeasure: registerValues.waistsize,
      reasonOfSubscription: registerValues.reasonOfSubscription
    }).then((user) => {
      if (user !== undefined) {
        dispatch(storePopulateUser(user))
        setLoginModal(false);
        setRegisterModal_4(false);
        setRegisterModal_5(true);
      }
    }).catch((e) => Alert.alert('Une erreur c\'est produite'))
  }
  
  const cgvUrl = 'https://belly-sculpting.com/cgv';

  const handleCGVBtn = useCallback(async () => {
    const supported = await Linking.canOpenURL(cgvUrl);
    if (supported) {
      await Linking.openURL(cgvUrl);
    }
  }, [cgvUrl]);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={styles.container}>
        <ImageBackground source={require('../assets/authentication-background.png')} style={styles.background}>
          {choicesModal && (
            <Text style={styles.text}>Découvrez la fusion parfaite {'\n'}du Stomach Vacuum et{'\n'} de la méthode Pilates</Text>
          )}
        </ImageBackground>
        {choicesModal && (
          <View style={stylesAuthChoices.authChoices}>
            <Pressable style={stylesAuthChoices.authChoicesBtn} onPress={() => {
              setChoicesModal(false);
              setLoginModal(true);
            }}>
              <Text style={stylesAuthChoices.authChoicesBtnText}>SE CONNECTER</Text>
            </Pressable>
            <View style={stylesAuthChoices.authChoicesRegister}>
              <Text style={stylesAuthChoices.authChoicesRegisterText}>Pas encore inscrit ?</Text>
              <Pressable onPress={() => {
                setChoicesModal(false);
                setRegisterModal(true);
              }}>
                <Text style={stylesAuthChoices.authChoicesRegisterLink}>Créer un compte</Text>
              </Pressable>
            </View>
          </View>
        )}
        {loginModal && (
          <View style={stylesAuthLogin.authLogin}>
            <View style={stylesAuthLogin.authLoginHeader}>
              <Text style={stylesAuthLogin.authLoginTitle}>SE CONNECTER</Text>
              <View style={stylesAuthLogin.authLoginRegister}>
                <Text style={stylesAuthLogin.authLoginRegisterText}>Pas encore inscrit ?</Text>
                <Pressable onPress={() => {
                  setLoginModal(false);
                  setRegisterModal(true);
                }}>
                  <Text style={stylesAuthLogin.authLoginRegisterLink}>Créer un compte</Text>
                </Pressable>
              </View>
            </View>
            <View style={stylesAuthLogin.authLoginForm}>
              <TextInput
                style={styles.input}
                underlineColorAndroid='transparent'
                placeholder='Adresse email'
                placeholderTextColor='#000'
                multiline={false}
                onChangeText={text => setLoginValues({ ...loginValues, email: text })}
                value={loginValues.email}
              />
              <TextInput
                style={styles.input}
                underlineColorAndroid='transparent'
                placeholder='Mot de passe'
                placeholderTextColor='#000'
                multiline={false}
                secureTextEntry
                onChangeText={text => setLoginValues({ ...loginValues, password: text })}
                value={loginValues.password}
              />
            </View>

            <Pressable style={stylesAuthLogin.authLoginBtn} onPress={() => submitLogin()}>
              <Text style={stylesAuthLogin.authLoginBtnText}>SE CONNECTER</Text>
            </Pressable>
            {/* <Text>Mot de passe oublié</Text> */}
          </View>
        )}
        {registerModal && (
          <View style={stylesAuthRegister.authRegister}>
            <View style={stylesAuthRegister.authRegisterHeader}>
              <Text style={stylesAuthRegister.authRegisterTitle}>S'INSCRIRE</Text>
              <View style={stylesAuthRegister.authRegisterRegister}>
                <Text style={stylesAuthRegister.authRegisterRegisterText}>Déja inscrit ?</Text>
                <Pressable onPress={() => {
                  setRegisterModal(false);
                  setLoginModal(true);
                }}>
                  <Text style={stylesAuthRegister.authRegisterRegisterLink}>Se connecter</Text>
                </Pressable>
              </View>
            </View>
            <View style={stylesAuthRegister.authRegisterForm}>
              <TextInput
                style={styles.input}
                underlineColorAndroid='transparent'
                placeholder='Nom'
                placeholderTextColor='#000'
                multiline={false}
                onChangeText={text => setRegisterValues({ ...registerValues, lastname: text })}
                value={registerValues.lastname}
              />
              <TextInput
                style={styles.input}
                underlineColorAndroid='transparent'
                placeholder='Prénom'
                placeholderTextColor='#000'
                multiline={false}
                onChangeText={text => setRegisterValues({ ...registerValues, firstname: text })}
                value={registerValues.firstname}
              />
              <DropDownPicker
                open={openSexeDropdown}
                value={valueSexeDropdown}
                items={itemsSexeDropdown}
                setOpen={setOpenSexeDropdown}
                setValue={setValueSexeDropdown}
                setItems={setItemsSexeDropdown}
                placeholder={'Sexe'}
                style={styles.input}
              />
              <TextInput
                style={styles.input}
                underlineColorAndroid='transparent'
                placeholder='Adresse e-mail'
                placeholderTextColor='#000'
                multiline={false}
                onChangeText={text => setRegisterValues({ ...registerValues, email: text })}
                value={registerValues.email}
              />
              <TextInput
                style={styles.input}
                placeholder='Votre mot de passe'
                placeholderTextColor='#000'
                multiline={false}
                secureTextEntry
                onChangeText={text => setRegisterValues({ ...registerValues, password: text })}
                value={registerValues.password}
              />
              <TextInput
                style={styles.input}
                placeholder='Confirmez votre mot de passe'
                placeholderTextColor='#000'
                multiline={false}
                secureTextEntry
                onChangeText={text => setRegisterValues({ ...registerValues, passwordConfirm: text })}
                value={registerValues.passwordConfirm}
              />

              <View style={styles.checkboxContainer}>
                <Checkbox
                  style={styles.checkbox}
                  value={isNewsletterChecked}
                  onValueChange={() => setIsNewsletterChecked(!isNewsletterChecked)}
                  color={isNewsletterChecked ? 'black' : undefined}
                />
                <Text style={styles.label}>JE M’INSCRIS À LA BELLY COMMUNITY </Text>
              </View>
              <View style={styles.checkboxContainer}>
                <Checkbox
                  style={styles.checkbox}
                  value={isCGVAcceptedChecked}
                  onValueChange={() => setIsCGVAcceptedChecked(!isCGVAcceptedChecked)}
                  color={isCGVAcceptedChecked ? 'black' : undefined}
                />
                <Pressable onPress={handleCGVBtn}>
                  <Text style={styles.label}>J’ACCEPTE LES CGV</Text>
                </Pressable>
              </View>
            </View>

            <View style={stylesAuthRegister.authRegisterBtn}>
              <Pressable onPress={() => signupUser()}>
                <Text style={stylesAuthRegister.authRegisterBtnText}>SUIVANT</Text>
              </Pressable>
            </View>
            {/* <Text>Mot de passe oublié</Text> */}
          </View>
        )}
        {registerModal_1 && (
          <View style={stylesAuthRegister.authRegister}>
            <View style={stylesAuthRegister.authRegisterHeader}>
              <Text style={stylesAuthRegister.authRegisterTitle}>S'INSCRIRE</Text>
            </View>
            <View style={stylesAuthRegister.authRegisterForm}>
              <View style={stylesAuthRegister.authRegisterExtra}>
                <Text>VOTRE TOUR DE TAILLE ?</Text>
                <TextInput
                  style={styles.inputextra}
                  underlineColorAndroid='transparent'
                  placeholder='Tour de taille'
                  placeholderTextColor='#000'
                  multiline={false}
                  onChangeText={text => setRegisterValues({ ...registerValues, waistsize: parseInt(text) || 0 })}
                  value={registerValues.waistsize.toString()}
                />
                <Text>CM</Text>
              </View>
              <View style={stylesAuthRegister.authRegisterBtn}>
                <Pressable onPress={() => {
                  setLoginModal(false);
                  setRegisterModal_1(false);
                  setRegisterModal_2(true);
                }}>
                  <Text style={stylesAuthRegister.authRegisterBtnText}>SUIVANT</Text>
                </Pressable>
              </View>
            </View></View>
        )}
        {registerModal_2 && (
          <View style={stylesAuthRegister.authRegister}>
            <View style={stylesAuthRegister.authRegisterHeader}>
              <Text style={stylesAuthRegister.authRegisterTitle}>S'INSCRIRE</Text>
            </View>
            <View style={stylesAuthRegister.authRegisterForm}>
              <View style={stylesAuthRegister.authRegisterExtra}>
                <Text>VOTRE POIDS ?</Text>
                <TextInput
                  style={styles.inputextra}
                  underlineColorAndroid='transparent'
                  placeholder='XX'
                  placeholderTextColor='#000'
                  multiline={false}
                  onChangeText={text => setRegisterValues({ ...registerValues, weight: parseInt(text) || 0 })}
                  value={registerValues.weight.toString()}
                />
                <Text>KG</Text>
              </View>
              <View style={stylesAuthRegister.authRegisterBtn}>
                <Pressable onPress={() => {
                  setLoginModal(false);
                  setRegisterModal_2(false);
                  setRegisterModal_3(true);
                }}>
                  <Text style={stylesAuthRegister.authRegisterBtnText}>SUIVANT</Text>
                </Pressable>
              </View>
            </View></View>
        )}
        {registerModal_3 && (
          <View style={stylesAuthRegister.authRegister}>
            <View style={stylesAuthRegister.authRegisterHeader}>
              <Text style={stylesAuthRegister.authRegisterTitle}>S'INSCRIRE</Text>
            </View>
            <View style={stylesAuthRegister.authRegisterForm}>
              <View style={stylesAuthRegister.authRegisterExtra}>
                <Text>QUEL EST TON BESOIN ?  ?</Text>
                <TextInput
                  style={styles.inputextramood}
                  underlineColorAndroid='transparent'
                  placeholder='Mon besoin ... '
                  placeholderTextColor='#000'
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={text => setRegisterValues({ ...registerValues, reasonOfSubscription: text })}
                  value={registerValues.reasonOfSubscription}
                />
              </View>
              <View style={stylesAuthRegister.authRegisterBtn}>
                <Pressable onPress={() => {
                  setLoginModal(false);
                  setRegisterModal_3(false);
                  setRegisterModal_4(true);
                }}>
                  <Text style={stylesAuthRegister.authRegisterBtnText}>SUIVANT</Text>
                </Pressable>
              </View>
            </View></View>
        )}
        {registerModal_4 && (
          <View style={stylesAuthRegister.authRegister}>
            <View style={stylesAuthRegister.authRegisterHeader}>
              <Text style={stylesAuthRegister.authRegisterTitle}>S'INSCRIRE</Text>
            </View>
            <View style={stylesAuthRegister.authRegisterForm}>
              <View style={stylesAuthRegister.authRegisterExtra}>
                <Text>PREND TON BELLY SELFIE !</Text>
                {photoProfile !== null && <Image source={{ uri: photoProfile }} style={stylesAuthRegister.IconImg} />}
                <Pressable
                  onPress={() => {
                    ImagePicker.launchImageLibraryAsync({
                      mediaTypes: ImagePicker.MediaTypeOptions.All,
                      allowsEditing: true, aspect: [4, 3], quality: 1,
                      base64: true
                    }).then((result) => {
                      if (result.canceled) return                  
                      if(result.assets![0].base64) {
                        const imageUri = `data:image/png;base64,${result.assets![0].base64}`;
                        RESTUserUploadImage(token, imageUri, 'photoProfile')
                          .then((user) => {
                            if(user !== undefined) {
                              dispatch(storePopulateUser(user)) 
                              setPhotoProfile(user.photoProfile)
                            } else { 
                              Alert.alert('Une erreur s\'est produite lors du chargement de l\'image')
                            }
                          })
                          .catch((e) => console.log(e))
                      } else {
                        Alert.alert('Erreur lors du chargement de l\'image')
                      }
                    })
                  }}>
                  <Image style={styles.icon_pic} source={require('../assets/icon__picture.png')} />
                </Pressable>
              </View>
              <View style={stylesAuthRegister.authRegisterBtn}>
                <Pressable onPress={() => updateUserInfos()}>
                  <Text style={stylesAuthRegister.authRegisterBtnText}>S'ENREGISTRER</Text>
                </Pressable>
              </View>
              <Text style={stylesAuthRegister.BtnSkip}>PLUS TARD</Text>
            </View></View>
        )}
        {registerModal_5 && (
          <View style={stylesAuthRegister.authRegister}>

            <View style={stylesAuthRegister.authRegisterForm}>
              <View style={stylesAuthRegister.authRegisterExtra}>
                <Text style={stylesAuthRegister.headtitle}>HELLO { entity?.firstName.toUpperCase() } !</Text>
                <Text style={stylesAuthRegister.headdesc}>Bienvenue à toi sur cette nouvelle application,
                  je suis très heureuse que tu rejoignes la Belly Adventure !{'\n'}{'\n'}
                  Sur cette application, tu retrouveras l’ensemble du programme BellySculpting accessible à toutes les femmes désireuses de retrouver un ventre plus plat mais surtout un bien-être global.{'\n'}{'\n'}N’oublie surtout pas de prendre ta photo avant de débuter quoi que ce soit, ici on est sans jugement, on se fait du bien et on apprend à créer une routine durable dans le temps.{'\n'}{'\n'}
                  Vidéos sport, contenus alimentation, et  plusieurs autres nouveautés t’attendent et arrive bientôt !</Text>
              </View>
              <View style={stylesAuthRegister.authRegisterBtn}>
                <Pressable onPress={() => navigation.navigate('Home')}>
                  <Text style={stylesAuthRegister.authRegisterBtnText}>S'ENREGISTRER</Text>
                </Pressable>
              </View>
            </View>
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  )
}


const styles = StyleSheet.create({
  icon_pic: {
    width: 50,
    height: 50,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    alignItems: 'center',
    display: 'flex'
  },
  label: {
    marginLeft: 8,
    fontSize: 10,
    alignItems: 'center',
    display: 'flex'
  },
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#000'
  },
  background: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontWeight: '400',
    fontSize: 20,
    lineHeight: 30,
    color: '#fff',
    textAlign: 'center'
  },
  input: {
    width: '100%',
    borderRadius: 4,
    fontWeight: '400',
    fontSize: 15,
    color: '#000',
    borderWidth: 1,
    padding: 15,
    borderBottomColor: '#000',

    textAlign: 'center'
  },
  inputextra: {
    maxWidth: '100%',
    fontSize: 35,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  inputextramood: {
    fontSize: 15,
    borderWidth: 1,
    borderRadius: 5,
    height: 200,
    width: '100%',
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
  }
})

const stylesAuthChoices = StyleSheet.create({
  authChoices: {
    width: '90%',
    height: 130,
    position: 'absolute',
    bottom: 30,
    left: '5%',
    backgroundColor: '#fff',
    opacity: 0.7,
    borderRadius: 5,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  authChoicesBtn: {
    width: '90%',
    borderWidth: 1,
    borderColor: '#000',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5
  },
  authChoicesBtnText: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center'
  },
  authChoicesRegister: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 5
  },
  authChoicesRegisterText: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
  },
  authChoicesRegisterLink: {
    color: '#000',
    fontWeight: '700',
    fontSize: 15,
    textDecorationLine: 'underline',
    textDecorationColor: '#fff'
  },
})

const stylesAuthLogin = StyleSheet.create({
  authLogin: {
    width: '90%',
    position: 'absolute',
    bottom: 30,
    left: '5%',
    backgroundColor: '#fff',
    opacity: 0.7,
    borderRadius: 5,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  authLoginHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  authLoginTitle: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
  },
  authLoginRegister: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    gap: 5
  },
  authLoginRegisterText: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
  },
  authLoginRegisterLink: {
    color: '#000',
    fontWeight: '700',
    fontSize: 15,
    textDecorationLine: 'underline',
    textDecorationColor: '#fff'
  },
  authLoginForm: {
    marginTop: 40,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  authLoginBtn: {
    marginTop: 40,
    backgroundColor: '#000',
    opacity: 1,
    width: '100%',
    borderWidth: 1,
    borderColor: '#000',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5
  },
  authLoginBtnText: {
    color: '#fff',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center'
  },
})

const stylesAuthRegister = StyleSheet.create({
  headtitle: {
    fontSize: 20,
    fontWeight: '800'
  },
  headdesc: {
    fontSize: 15,
    fontWeight: '800'
  },
  BtnSkip: {
    textAlign: 'center'
  },
  IconImg: {
    height: 100,
    width: 100,
    borderRadius: 100
  },
  authRegisterExtra: {
    height: 400,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  authRegister: {
    width: '90%',
    position: 'absolute',
    bottom: 30,
    left: '5%',
    backgroundColor: '#fff',
    opacity: 0.7,
    borderRadius: 5,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  authRegisterHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  authRegisterTitle: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
  },
  authRegisterRegister: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    gap: 5
  },
  authRegisterRegisterText: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
  },
  authRegisterRegisterLink: {
    color: '#000',
    fontWeight: '700',
    fontSize: 15,
    textDecorationLine: 'underline',
    textDecorationColor: '#fff'
  },
  authRegisterForm: {
    marginTop: 40,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  authRegisterBtn: {
    marginTop: 40,
    backgroundColor: '#fff',
    opacity: 1,
    width: '100%',
    borderWidth: 1,
    borderColor: '#000',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5
  },
  authRegisterBtnText: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center'
  },

})