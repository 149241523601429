import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ICategory } from "../../libs/Models";

interface CategoriesState {
  entities: ICategory[]
}

const initialState: CategoriesState = {
  entities: []
};

const CategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategories(state, action: PayloadAction<ICategory[]>) {
      state.entities = action.payload
    },
  },
});

export const { addCategories } = CategoriesSlice.actions;

export default CategoriesSlice.reducer;