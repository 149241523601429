import { FC, useEffect } from "react";
import { ImageBackground, ScrollView, StyleSheet, Text, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { VideoThumb } from "../components/VideoThumb";
import { useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { selectFavoritesVideos } from "../store/modules/Videos";
import { IExtentedVideo } from "../libs/Models";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Favorites'> { }

export const Favorites: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
  }, [_props, isFocused])

  let favoritesVideos = entity ? useTypedSelector((state) => selectFavoritesVideos(state.videos, entity._id)) : [];

  return (
    <ScrollView contentContainerStyle={styles.container} showsHorizontalScrollIndicator={false}>
      <View style={styles.description}>
        <Text style={styles.descriptionText}>Toutes vos vidéos préférées  </Text>
        <ImageBackground style={styles.heart} source={require('../assets/icon__heart.png')} />
      </View>
      <View style={styles.content}>
        {favoritesVideos.map((video: IExtentedVideo) =>
          <View key={video.id} style={styles.content_video}>
            <View style={styles.content_video_container}>
              <VideoThumb style={styles.video} disabled={(!authenticated || ( entity ? !entity?.isSubscribed : false))} video={video} category={video.categories[0].name} />
            </View>
          </View>
        )}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  titlefav: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center'
  },
  heart: {
    width: 20,
    height: 20,
    marginLeft: 5,
    marginRight: 5,
    lineHeight: 1
  },

  container: {
    display: 'flex',
    flexDirection: 'column',

    paddingBottom: 25,
    gap: 25
  },
  description: {
    backgroundColor: '#252525',
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  descriptionText: {
    color: '#fff',
    fontWeight: '700',

    fontSize: 15,
    lineHeight: 30,
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    flex: 1,

    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  content_video_container: {
    padding: 15,
    width: '100%',
    alignItems: 'center'
  },
  content_video: {
    width: '50%',
  },
});
