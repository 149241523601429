import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { IUser } from "../../libs/Models";

interface AuthenticationState {
    authenticated: boolean,
    token: string,
    entity: IUser | null
}

const initialState: AuthenticationState = {
    authenticated: false,
    token: '',
    entity: null
};

const AuthenticationSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        storeSetAuthenticated(state, action: PayloadAction<boolean>) {
            return state = { ...state, authenticated: action.payload }
        },
        storePopulateToken(state, action: PayloadAction<string>) {
            return state = { ...state, token: action.payload }
        },
        storePopulateUser(state, action: PayloadAction<IUser | null>) {
            return state = { ...state, entity: action.payload }
        },
    },
});

export const { storeSetAuthenticated, storePopulateToken, storePopulateUser } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;