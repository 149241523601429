import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IPlanning } from "../../libs/Models";

interface PlanningsState {
  entities: IPlanning[]
}

const initialState: PlanningsState = {
  entities: []
};

const PlanningsSlice = createSlice({
  name: 'plannings',
  initialState,
  reducers: {
    addPlannings(state, action: PayloadAction<IPlanning[]>) {
      state.entities = action.payload
    },
  },
});

export const { addPlannings } = PlanningsSlice.actions;

export default PlanningsSlice.reducer;