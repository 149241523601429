import axios from 'axios';
import { IUser } from './Models';

export const RESTUserUpdate = async (token: string, data: any): Promise<IUser | undefined> => {
    const response = await axios({
      url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/update-user-info',
      method: 'post',
      headers: { 'x-access-token': token },
      data
    });

    if(response.status === 200) {
      return response.data;
    }

    return undefined;
}

export const RESTUserUpdatePassword = async (token: string, data: any): Promise<IUser | undefined> => {
    const response = await axios({
      url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/change-password',
      method: 'post',
      headers: { 'x-access-token': token },
      data
    });

    if(response.status === 200) {
      return response.data;
    }

    return undefined;
}

export const RESTUserUploadImage = async (token: string, base64Image: string, property: string): Promise<IUser | undefined> => {
    const response = await axios({
      url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/update-pictures',
      method: 'post',
      headers: { 'x-access-token': token },
      data: {
        property,
        image64: base64Image
      }
    });

    if(response.status === 200) {
      return response.data;
    }

    return undefined;
}