import axios from 'axios';
import { IPlanning } from './Models';

export const GRAPHQLGetPlannings = async (): Promise<IPlanning[]> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: {
      query: `
        query getGetWeekDays {
          GetWeekDays {
            id
            weekNumber
            packTag
            weekDates
            weekType
            days {
              dayNumber
              dayTitle
              activities {
                title
                videoId
                buttonName
              }
            }
          }
        }
        `
    }
  });

  if(response.status === 200) {
    return response.data.data.GetWeekDays
  }

  return []
}