import axios from "axios";

export const NODEMAILERSendMail = async (token: string, { email, reason, message }: { email: string, reason: string, message: string }): Promise<string | undefined> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/report',
    method: 'post',
    headers: { 'x-access-token': token },
    data: {
      email,
      reason,
      report: message 
    }
  });

  if(response.status === 200) {
    return response.data.message;
  }

  return undefined;
};