import { FC, useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { VideoThumb } from "../components/VideoThumb";
import { Text } from "../components/Text";
import { IExtentedVideo } from "../libs/Models";
import { HeaderBarTitle } from "../components/HeaderBarTitle";
import { useTypedSelector } from "../store";
import { selectVideosByCategory } from "../store/modules/Videos";
import { useIsFocused } from "@react-navigation/native";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Category'> { }

export const Category: FC<Props> = (_props: Props) => {
  const { route, navigation } = _props;
  const { category } = route.params;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
    !category && navigation.navigate("Home")
    navigation.setOptions({ headerTitle: (props) => <HeaderBarTitle title={category!.name} {...props} /> })
  }, [_props, isFocused])


  const videos = useTypedSelector((state) => selectVideosByCategory(state.videos, category!.id));

  return (
    <ScrollView contentContainerStyle={styles.container} showsHorizontalScrollIndicator={false}>
      {/* <View style={styles.description}>
        <Text style={styles.descriptionText}>Une routine express en 3 minutes top chrono.{"\n"}Prendre quotidiennement soin de sa peau{"\n"}est très important pour la santé physique et mentale.</Text>
      </View> */}
      <View style={styles.content}>

        {videos.map((video: IExtentedVideo) =>
          <View key={video.id} style={styles.content_video}>
            <View style={styles.content_video_container}>
              <VideoThumb disabled={(!authenticated || ( entity ? !entity?.isSubscribed : false))} video={video} category={category!.name} />
            </View>
          </View>
        )}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 25,
    paddingBottom: 25,
    gap: 25
  },
  description: {
    backgroundColor: '#252525',
    paddingTop: 20,
    paddingBottom: 20,
  },
  descriptionText: {
    color: '#fff',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 30,
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: "center"
  },
  content_video_container: {

    width: '100%',

  },
  content_video: {
    width: '100%',
    maxWidth: 180,
    margin: 10,
    height: 180,

  }
});
