import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import AuthenticationReducer from "./modules/Authentication";
import CategoriesReducer from "./modules/Categories";
import VideosReducer from "./modules/Videos";
import CoursReducer from "./modules/Cours";
import PlanningsReducer from "./modules/Plannings";

const rootReducer = combineReducers({
  authentication: AuthenticationReducer,
  categories: CategoriesReducer,
  videos: VideosReducer,
  cours: CoursReducer,
  plannings: PlanningsReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useTypedDispatch = useDispatch<RootDispatch>;

export default store;