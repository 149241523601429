import { StyleSheet, Text, View } from "react-native"
import { FC } from "react"
import Ionicons from '@expo/vector-icons/Ionicons';
import { useNavigation } from '@react-navigation/native';

interface Props {}

export const HeaderBarLeft: FC<Props> = (_props: Props) => {
  const navigation = useNavigation();

    return (
      <View>
        <Ionicons name="chevron-back-outline" size={35} color={'#fff'} style={styles.return} onPress={() => navigation.goBack()} />
      </View>
    )
}

const styles = StyleSheet.create({
    return: {
      width: 35,
      height: 35,
      marginLeft: 10,
    },
  });