import axios from "axios";
import { IUser } from "./Models";

export const RESTAuthenticationLogin = async ({ email, password }: { email: string, password: string }): Promise<{
  auth: boolean,
  token: string
} | undefined> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/signin',
    method: 'post',
    data: {
      email,
      password
    }
  });

  if (response.status === 200) {
    return response.data;
  }

  return undefined;
}

export const RESTAuthenticationRegister = async ({ email, password, sexe, firstName, lastName, newsletterOptin }: { email: string, password: string, sexe: string, firstName: string, lastName: string, newsletterOptin: boolean }): Promise<{
  auth: boolean,
  token: string
} | undefined> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users/signup',
    method: 'post',
    data: {
      email,
      password,
      firstName,
      lastName,
      sexe,
      newsletterOptin
    }
  });

  if (response.status === 200) {
    return response.data;
  }

  return undefined;
}

export const RESTAuthenticationGetUser = async (token: string): Promise<IUser | undefined> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/api/v1/users',
    method: 'get',
    headers: {
      'x-access-token': token
    }
  });

  if (response.status === 200) {
    return response.data;
  }

  return undefined;
}