import axios from "axios";
import { IExtentedVideo } from "./Models";

export const GRAPHQLGetVideos = async (): Promise<IExtentedVideo[]> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: {
      query: `
        query getVideosExtended {
          videosExtended {
            id
            position
            videoId
            vimeoID
            name
            image
            duration
            categories {
              id
              name
            }
            favorites {
              id
            }
            url240
            url360
            url540
            url720
            url1080
            urlHLS
          }
        }
      `
    }
  });

  if(response.status === 200) {
    return response.data.data.videosExtended;
  }

  return [];
}

export const GRAPHQLAddUserToVideoFavorites = async (videoID: string, userID: string): Promise<IExtentedVideo | undefined> => {
  const graphqlQuery = {
    "operationName": "AddUserToVideoFavorites",
    "query": `
      mutation AddUserToVideoFavorites($id: ID!, $user: ID!) {
        addFavorite(id: $id, user: $user) {
          id
          position
          name
          image
          duration
          categories {
            id
            name
          }
          favorites {
            id
          }
        }
      }
    `,
    "variables": { id: videoID, user: userID }
  };

  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: graphqlQuery
  });

  if(response.status === 200) {
    return response.data.data.addFavorite;
  }

  return undefined;
}

export const GRAPHQLRemoveUserToVideoFavorites = async (videoID: string, userID: string): Promise<IExtentedVideo | undefined> => {
  const graphqlQuery = {
    "operationName": "RemoveUserToVideoFavorites",
    "query": `
      mutation RemoveUserToVideoFavorites($id: ID!, $user: ID!) {
        removeFavorite(id: $id, user: $user) {
          id
          position
          name
          image
          duration
          categories {
            id
            name
          }
          favorites {
            id
          }
        }
      }
    `,
    "variables": { id: videoID, user: userID }
  };

  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: graphqlQuery
  });

  if(response.status === 200) {
    return response.data.data.removeFavorite;
  }

  return undefined;
}