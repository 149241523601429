import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect, useState } from "react";
import { VideosThumb } from "../components/VideosThumb";
import { SwipeablePanel } from 'rn-swipeable-panel';
import Checkbox from 'expo-checkbox';
import { useTypedSelector } from "../store";
import { ICategory } from "../libs/Models";
import { useIsFocused } from "@react-navigation/native";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Categories'> { }

export const Categories: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
  }, [_props, isFocused])

  let categories = useTypedSelector((state) => state.categories.entities);

  const fitlersInitialState: any = {};
  categories.forEach((category) => {
    fitlersInitialState[category.id] = true;
  })

  const [isFiltersPanelActive, setIsFiltersPanelActive] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: boolean }>(fitlersInitialState);
  const [filtersPanelProps] = useState({
    fullWidth: true,
    onlySmall: true,
    smallPanelHeight: 700,
    showCloseButton: true,
    closeOnTouchOutside: true,
    noBar: true,
    onClose: () => closeFiltersPanel(),
    onPressCloseButton: () => closeFiltersPanel(),
  });

  const openFiltersPanel = () => {
    setIsFiltersPanelActive(true);
  };

  const closeFiltersPanel = () => {
    setIsFiltersPanelActive(false);
  };

  const setSelectedFiltersObject = (id: string) => {
    let initial = selectedFilters;
    initial = { ...initial, [id]: !initial[id] }
    setSelectedFilters(initial)
  }

  return (
    <>
      <ScrollView contentContainerStyle={styles.container} showsVerticalScrollIndicator={false}>
        {categories.map((category: ICategory) => {
          if (selectedFilters[category.id]) {
            return <VideosThumb key={category.id} disabled={(!authenticated || ( entity ? !entity?.isSubscribed : false))} category={category} title={category.name} />
          }
        })}
      </ScrollView>
      <Text style={styles.openpanelbutton} onPress={() => openFiltersPanel()}>
        FILTRER
      </Text>
      <SwipeablePanel {...filtersPanelProps} isActive={isFiltersPanelActive} style={styles.panel}>
        <ScrollView style={styles.filtercontainer} >
          <View style={{ marginBottom: 50 }}>
            {categories.map((category, index) => (
              <Pressable key={index} style={styles.checkboxContainer} onPress={() => setSelectedFiltersObject(category.id)} >
                <Checkbox
                  style={styles.checkbox}
                  value={selectedFilters[category.id]}
                  color={'#000'}
                />
                <Text style={styles.label}>{category.name}</Text>
              </Pressable>
            ))}
          </View>
        </ScrollView>
      </SwipeablePanel>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 25,
    display: 'flex',
    flexDirection: 'column',
    gap: 25
  },
  header: {
    fontWeight: "bold",
    marginBottom: 20,
    fontSize: 36,
  },
  openpanelbutton: {
    width: '90%',
    position: 'absolute',
    textAlign: 'center',
    paddingBottom: 15,
    paddingTop: 15,
    bottom: '3%',
    left: '5%',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filtercontainer: {
    width: '100%',
    height: 800,
    position: 'relative'
  },
  openpanelbuttonAbs: {
    width: '90%',
    position: 'relative',
    textAlign: 'center',
    paddingBottom: 15,
    paddingTop: 15,
    bottom: '3%',
    left: '5%',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  panel: {
    height: 700,
    width: '100%',
    paddingVertical: 25,
    paddingHorizontal: '5%',
    position: 'relative'
  },
  checkboxContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: 8,
  }
});
