import { registerRootComponent } from "expo";
import { Provider } from "react-redux";

import Native from "./App";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <Native />
    </Provider>
  );
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
