import { Link } from "@react-navigation/native"
import { ImageBackground, StyleSheet, View } from "react-native"
import { Text } from "./Text"
import Ionicons from '@expo/vector-icons/Ionicons';
import { IExtentedVideo } from "../libs/Models";
import { FontDisplay } from "expo-font";

interface Props {
    video: IExtentedVideo,
    category: string,
    disabled: boolean,
    style?: { [key: string]: any }
}

export const VideoThumb = ({ video, category, disabled = false }: Props) => {
    return (
        <Link to={disabled ? { screen: 'Authentication' } : { screen: 'Video', params: { video } }}>
            <View style={styles.container}>
                <View style={styles.image}>
                    {disabled && <View style={styles.disabledLayer} />}
                    <ImageBackground source={{ uri: video.image }} imageStyle={{ borderRadius: 13 }} style={styles.background} />
                    {disabled ? (
                        <Text style={styles.time}>
                            <Ionicons name={'lock-open-outline'} color={'#000'} size={14} />
                        </Text>
                    ) : (
                        <Text style={styles.time}>
                            {video.duration}
                        </Text>
                    )}
                </View>
                <View style={styles.content}> 
                <Text style={styles.title}>{video.name}</Text>
                <Text style={styles.category}>{category}</Text>
                </View>
            </View>
        </Link>
    )
}

const styles = StyleSheet.create({
    content:{
        maxWidth:180,
    },
    container: {
    
        maxWidth: 180,
width:180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        
        
    },
    image: {
        width: '100%', 
        height: 130,
        maxWidth:180,
    },
    disabledLayer: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        width: '100%',
        maxWidth:180,
        height: 130,
        zIndex: 99
    },
    background: {
        width: '100%',
        maxWidth:180,
        height: 130,
        resizeMode: 'cover',
     
    },
    time: {
        width: 42,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: 9,
        left: 9,
        textAlign: 'center',
        fontSize: 10,
        borderRadius: 5
    },
    title: {
        marginTop: 7.5,
        marginBottom:2.5,
        fontSize: 12,
        fontWeight: '600',
        flexWrap: 'wrap'
       
    },
    category: {
      
        fontSize: 10,
        fontWeight: '400'
    }
})