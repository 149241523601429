import { FC, useEffect } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { useTypedSelector } from "../store";
import { Link, useIsFocused } from "@react-navigation/native";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Cours'> { }

export const Cours: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
  }, [_props, isFocused])

  let cours = useTypedSelector((state) => state.cours.entities);

  return (
    <ScrollView contentContainerStyle={styles.containerTop} showsHorizontalScrollIndicator={false}>
      <View style={styles.ContentDescription}>
        <Link style={styles.link_title} to={{ screen: 'Contact' }} >
          <Text style={styles.descriptionText}>Pour toute demande de coaching personnalisé, contactez-moi !</Text>
        </Link>
      </View>
      <View style={styles.container}>
        <Text style={styles.title}>COURS EN STUDIO</Text>

        {cours.map((cour, index) => {
          return (

            <Link style={[styles.background_cours]} key={index} to={{ screen: "CoursSingle", params: { cours: cour } }}>
              <View style={[styles.background_cours_content]}>
                <View style={styles.layer}>
                  <Text style={styles.primary}>{cour.name}</Text>
                  <Text style={styles.secondary}>{cour.lieu?.toUpperCase()}</Text>
                </View>
                <View style={styles.date__content}>
                  <Text style={styles.date}>{cour.date}</Text>
                </View>
              </View>
            </Link>
          )
        })}


        {/* <View style={[styles.background_cours]}>
                <View style={styles.layer}> 
                        <Text style={styles.primary}>Hot Pilates</Text>
                        <Text style={styles.secondary}>CHEZ UMAN PROJECT // LAPPARTEMENT</Text>
                </View>
                <View style={styles.date__content}>
                        <Text style={styles.date}>18/07</Text>
                      
                </View>
            </View>
            <Text style={styles.title}>FORMATIONS</Text>
            <View style={[styles.background_cours, styles.rose]}>
                <View style={styles.layer}> 
                        <Text style={styles.primary}>Hot Pilates</Text>
                        <Text style={styles.secondary}>CHEZ UMAN PROJECT // LAPPARTEMENT</Text>
                </View>
                <View style={styles.date__content}>
                        <Text style={styles.date}>18/07</Text>
                      
                </View>
            </View>
            <View style={[styles.background_cours, styles.rose]}>
                <View style={styles.layer}> 
                        <Text style={styles.primary}>Hot Pilates</Text>
                        <Text style={styles.secondary}>CHEZ UMAN PROJECT // LAPPARTEMENT</Text>
                </View>
                <View style={styles.date__content}>
                        <Text style={styles.date}>18/07</Text>
                      
                </View>
            </View> */}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  link_title: {
    padding: 20
  },
  ContentDescription: {
    backgroundColor: '#252525',
    paddingTop: 20,
    paddingBottom: 20,
  },
  layer: {
    marginBottom: 30
  },
  date__content: {
    flexDirection: 'row',
  },
  rose: {
    backgroundColor: '#E584BB'
  },
  date: {
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 20,
    color: 'black',
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10
  },
  primary: {
    fontSize: 30,
    color: 'white',
  },
  secondary: {
    fontSize: 12.5,
    color: 'white',
  },
  title: {
    color: 'black',
    fontWeight: '700',
    fontSize: 15
  },
  background_cours_content: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',

  },
  background_cours: {

    width: '100%',
    backgroundColor: '#252525'
  },
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 15,
    gap: 25
  },
  description: {
    backgroundColor: '#252525',
    padding: 20
  },
  descriptionText: {
    color: '#fff',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 30,

    textAlign: 'center'
  },
  content: {
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: 10
  },
  video: {
    width: '50%'
  }
});
