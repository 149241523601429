import { Button, Dimensions, ImageBackground, Platform, Pressable, StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect, useRef, useState } from "react";
import { HeaderBarRight } from "../components/HeaderBarRight";
import Ionicons from '@expo/vector-icons/Ionicons';
import { Text } from "../components/Text";
import WebView from "react-native-webview";
import { useTypedDispatch, useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { GRAPHQLAddUserToVideoFavorites, GRAPHQLRemoveUserToVideoFavorites } from "../libs/VideosQueries";
import { selectFavoritesVideos, updateVideo } from "../store/modules/Videos";
import { Video as VideoPlayer, VideoFullscreenUpdateEvent } from 'expo-av';

interface Props extends NativeStackScreenProps<RootStackParamList, 'Video'> { }

export const Video: FC<Props> = (_props: Props) => {
  const { route, navigation } = _props;
  const { video } = route.params;
  let videoRef = useRef<VideoPlayer>(null);
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const dispatch = useTypedDispatch();
  const { authenticated, entity } = authentication;

  let favoritesVideos = entity !== null ? useTypedSelector((state) => selectFavoritesVideos(state.videos, entity._id)) : [];
  let isFavoriteVideo = !!favoritesVideos.find(el => el.id === video!.id)

  useEffect(() => {
    video === null && navigation.navigate("Home");
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");
    navigation.setOptions({ headerRight: (props) => <HeaderBarRight videoLike liked={isFavoriteVideo} {...props} /> })
  }, [_props, isFocused, isFavoriteVideo])

  const playVideo = () => {
    videoRef.current?._setFullscreen(true);
    videoRef.current?.playAsync();
  }

  const fullScreenUpdateFunction = (props: VideoFullscreenUpdateEvent) => {
    const { fullscreenUpdate } = props;
    if (fullscreenUpdate === 3) {
      videoRef.current?._setFullscreen(false);
      videoRef.current?.stopAsync();
    }
  }

  const favoriteAction = () => {
    if (video && entity !== null) {
      if (isFavoriteVideo) {
        GRAPHQLRemoveUserToVideoFavorites(video.id, entity._id)
          .then((response) => response != undefined && dispatch(updateVideo(response)))
          .catch((e) => console.log(e))
      } else {
        GRAPHQLAddUserToVideoFavorites(video.id, entity._id)
          .then((response) => response != undefined && dispatch(updateVideo(response)))
          .catch((e) => console.log(e))
      }
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <ImageBackground source={{ uri: video!.image }} style={styles.background} />
        <Text style={styles.time}>
          {video!.duration}
        </Text>
      </View>

      <VideoPlayer
        ref={videoRef}
        style={styles.video}
        source={{
          uri: video!.urlHLS
        }}
        useNativeControls
        isLooping
        onFullscreenUpdate={(props) => fullScreenUpdateFunction(props)}
      />
      <View style={styles.info}>
        <Text style={styles.title}>{video!.name}</Text>
        <Text style={styles.category}>{video!.categories[0].name}</Text>
        <Pressable onPress={() => playVideo()} style={styles.playbutton}>
          <Ionicons name={'play-outline'} color={'#fff'} size={20} />
          <Text style={{ color: '#fff' }}>LANCER LA VIDEO</Text>
        </Pressable>
        <Pressable onPress={() => favoriteAction()} style={styles.favoritebutton}>
          <Ionicons name={isFavoriteVideo ? 'heart' : 'heart-outline'} color={'#fff'} size={20} />
          <Text style={{ color: '#fff' }}>AJOUTER A MES VIDEOS</Text>
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff"
  },
  header: {
    height: 300,
  },
  background: {
    flex: 1,
    resizeMode: 'cover',
  },
  time: {
    width: 42,
    backgroundColor: '#fff',
    position: 'absolute',
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    bottom: 9,
    left: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    borderRadius: 5
  },
  info: {
    paddingTop: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: '400',
    textAlign: 'center'
  },
  category: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: '400',
    color: '#767676'
  },
  playbutton: {
    marginTop: 15,
    width: '90%',
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#F47DBE',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 5,
    alignItems: 'center'
  },
  favoritebutton: {
    marginTop: 10,
    width: '90%',
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 5,
    alignItems: 'center'
  },
  frameVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    // width: vw(100),
    // height: vh(100),
    zIndex: 1
  },
  video: {
    // opacity: 1,
    height: 0
  }
});