import axios from 'axios';
import { ICategory } from './Models';

export const GRAPHQLGetCategories = async (): Promise<ICategory[]> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: {
      query: `
        query getCategories {
          categories {
            id
            name
            position
          }
        }
        `
    }
  });

  if(response.status === 200) {
    return response.data.data.categories;
  }

  return [];
}