import { FC, HTMLAttributes } from "react";
import { StyleSheet, Text as BaseText } from "react-native";

interface Props extends HTMLAttributes<HTMLParagraphElement> {
    style?: {[key: string]: any}
};

export const Text: FC<Props> = (_props: Props) => {
    return <BaseText style={[ _props.style, styles.title ]}>{ _props.children }</BaseText>
}

const styles = StyleSheet.create({
    title: {
        fontFamily: 'HelveticaNeue'
    }
})