import { Image, StyleSheet, Pressable, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect } from "react";
import { Text } from '../components/Text';
import { useTypedDispatch, useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { Link } from "@react-navigation/native"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { storePopulateToken, storePopulateUser, storeSetAuthenticated } from "../store/modules/Authentication";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Profile'> { }

export const Profile: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const dispatch = useTypedDispatch();
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
  }, [_props, isFocused])

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image
          style={styles.profilepicture}
          source={{ uri: authenticated && entity && entity.photoProfile ? entity.photoProfile : "https://source.unsplash.com/user/c_v_r/100x100" }}
        />
        <View style={styles.headertexts}>
          <Text style={styles.textHello}>Hello !</Text>
          <Text style={styles.textFullname}>{entity?.firstName} {entity?.lastName}</Text>
        </View>
      </View>
      <View style={styles.summaries}>
        <Text style={styles.summariesTitle}>INFORMATIONS PERSONNELLES</Text>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>NOM</Text>
          <Text style={styles.summariesDataData}>{entity?.firstName.toUpperCase()}</Text>
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>PRÉNOM</Text>
          <Text style={styles.summariesDataData}>{entity?.lastName.toUpperCase()}</Text>
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>EMAIL</Text>
          <Text style={styles.summariesDataData}>{entity?.email.toUpperCase()}</Text>
        </View>
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>SEXE</Text>
          <Text style={styles.summariesDataData}>{entity?.sexe?.toUpperCase() || 'FEMME'}</Text>
        </View>
        <Link style={styles.btn__container} to={{ screen: 'ProfileEdit' }}>
          <Text style={styles.SaveBtn}>MODIFIER MON PROFIL</Text>
        </Link>
        {/* <Pressable style={styles.btn__container} onPress={() => {
          AsyncStorage.removeItem('@authentication_token')
            .then(() => {
              dispatch(storeSetAuthenticated(false))
              dispatch(storePopulateToken(''))
              dispatch(storePopulateUser(null))
            })
            .catch((e) => console.log(e))
          navigation.navigate('Home')
        }}>
          <Text style={styles.SaveBtn}>DECONNEXION</Text>
        </Pressable> */}
        <Text style={styles.summariesTitle}>MON JOURNAL DE BORD</Text>
        <View style={styles.board}>
          <View style={styles.content__board}>
            <View style={styles.mood__board}>
              <Link style={styles.link_title} to={{ screen: 'Mood' }} >
                <Text style={styles.title__board}>MON MOOD</Text>
              </Link>
              <Image style={styles.img__icon} source={require('../assets/icon__smile.png')}></Image>
            </View>
            <View style={styles.abonnement__board}>
              <Link style={styles.link_title} to={{ screen: 'Abonnement' }} >
                <Text style={styles.title__board} >MON ABONNEMENT</Text>
              </Link>
              <Image style={styles.img__icon} source={require('../assets/icon__member.png')}></Image>
            </View>
          </View>

          <View style={styles.stats}>
            <Link style={styles.link_title} to={{ screen: 'Stats' }} >
              <Text style={styles.title__board} >MES STATS</Text>

            </Link>
            <Image style={styles.img__icon} source={require('../assets/icon__stats.png')}></Image>
          </View>


        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  btn__container: {
    marginTop: 10,
    marginBottom: 5,
    padding: 15,
    backgroundColor: 'black',
    textAlign: 'center',
  },
  SaveBtn: {
    color: '#fff',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center',
    width: '100%'
  },
  img__icon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    width: 32,
    height: 32,

  },
  link_title: {
    width: '100%',
    height: '100%',
    position: 'relative',


  },
  board: {
    display: 'flex',
    flexDirection: 'column'
  },
  title__board: {
    color: 'white',
    fontWeight: 'bold'
  },
  content__board: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 15,
    marginTop: 25,

    marginBottom: 15,
    width: '100%'
  },
  mood__board: {
    flex: 1,
    position: 'relative',
    padding: 15,
    borderRadius: 5,
    height: 100,
    backgroundColor: '#E584BB',
    textAlign: 'left',
    color: 'white'
  },
  abonnement__board: {
    flex: 1,
    padding: 15,
    position: 'relative',
    borderRadius: 5,
    height: 100,
    backgroundColor: '#C8639C',
    textAlign: 'left',
    color: 'white'
  },
  stats: {
    padding: 15,
    borderRadius: 5,
    height: 125,

    width: '100%',
    position: 'relative',
    backgroundColor: '#D66DA8',
    textAlign: 'left',
    color: 'white'
  },
  container: {
    height: '100%',
    width: '100%',

    paddingLeft: 20,
    paddingRight: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 20,
    gap: 20,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1
  },
  profilepicture: {
    width: 70,
    height: 70,
    borderRadius: 50,
    borderColor: '#fff',
    borderWidth: 2
  },
  headertexts: {
    display: 'flex',
    flexDirection: 'column'
  },
  textHello: {
    color: '#000',
    fontWeight: '300',
    fontSize: 24
  },
  textFullname: {
    color: '#000',
    fontWeight: '400',
    fontSize: 30
  },
  summaries: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0
  },
  summariesTitle: {
    color: '#000',
    fontSize: 14,
    paddingTop: 20,
    fontWeight: "800"
  },
  summariesData: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  summariesDataTitle: {
    color: '#000',
    fontWeight: '400',
    fontSize: 12
  },
  summariesDataData: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15
  }
});
