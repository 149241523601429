import { Image, StyleSheet, Text, View } from "react-native"
import { FC } from "react"
import Ionicons from '@expo/vector-icons/Ionicons';
import { Link } from "@react-navigation/native";
import { useTypedSelector } from "../store";

interface Props {
  videoLike?: boolean;
  liked?: boolean;
}

export const HeaderBarRight: FC<Props> = (_props: Props) => {
  
  let authentication = useTypedSelector((state) => state.authentication);
  
  const { authenticated, entity } = authentication;
  
  const { videoLike, liked } = _props;
    return (
        <View>
          { !videoLike ? 
            (
              <Link style={styles.link} to={{ screen: 'Profile' }}>
                <Image
                  style={styles.profile}
                  source={{uri: authenticated && entity && entity.photoProfile ? entity.photoProfile : "https://source.unsplash.com/user/c_v_r/100x100"}} 
                />
              </Link>
            ) : (
              <View style={styles.like}>
                <Ionicons name={liked ? 'heart' : 'heart-outline'} color={'#fff'} size={18} />
              </View>
            )
          }
        </View>
    )
}

const styles = StyleSheet.create({
    link: {
      width: 35,
      height: 35,
      marginRight: 10,
      marginTop: 5,
    },
    profile: {
      width: 35,
      height: 35,
      borderRadius: 50,
      borderColor: '#fff',
      borderWidth: 2
    },
    like: {
      width: 35,
      height: 35,
      marginRight: 20,
      borderRadius: 50,
      backgroundColor: '#262626',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff'
    }
  });