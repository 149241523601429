import { Image, Pressable, TextInput, StyleSheet, View, Alert, TouchableWithoutFeedback, Keyboard } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import React, { FC, useEffect, useState } from "react";
import { Text } from '../components/Text';
import { useTypedDispatch, useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import * as ImagePicker from 'expo-image-picker';
import { RESTUserUpdate, RESTUserUploadImage } from "../libs/UsersQueries";
import { storePopulateUser } from "../store/modules/Authentication";

interface Props extends NativeStackScreenProps<RootStackParamList, 'Stats'> { }

export const Stats: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  const dispatch = useTypedDispatch();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, token, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
  }, [_props, isFocused])

  const [startMeasure, setStartMeasure] = useState<number>(entity?.startMeasure ? entity?.startMeasure : 0);
  const [oneMonthMeasure, setOneMonthMeasure] = useState<number>(entity?.measureOneMonth ? entity?.measureOneMonth : 0);
  const [threeMonthMeasure, setthreeMonthMeasure] = useState<number>(entity?.measureThreeMonth ? entity?.measureThreeMonth : 0);

  const [startWeight, setStartWeight] = useState<number>(entity?.startWeight ? entity?.startWeight : 0);
  const [oneMonthWeight, setOneMonthWeight] = useState<number>(entity?.weightOneMonth ? entity?.weightOneMonth : 0);
  const [threeMonthWeight, setthreeMonthWeight] = useState<number>(entity?.weightThreeMonth ? entity?.weightThreeMonth : 0);

  const [startPicture, setStartPicture] = useState<string>(entity?.photoStart ?? '');
  const [oneMonthPicture, setOneMonthPicture] = useState<string>(entity?.photoOneMonth ?? '');
  const [threeMonthPicture, setThreeMonthPicture] = useState<string>(entity?.photoThreeMonth ?? '');

  const updateStats = () => {
    RESTUserUpdate(token, {
      startMeasure,
      measureOneMonth: oneMonthMeasure,
      measureThreeMonth: threeMonthMeasure,
      startWeight,
      weightOneMonth: oneMonthWeight,
      weightThreeMonth: threeMonthWeight,
    }).then((user) => {
      if (user !== undefined) {
        dispatch(storePopulateUser(user))
        navigation.navigate('Profile')
      }
    })
  }

  return (

    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={styles.container}>
        <View style={styles.summaries}>
          <Text style={styles.summariesTitle}>MON TOUR DE TAILLE</Text>
          <View style={styles.flexblock}>
            <View style={[styles.item__content, styles.fc]}>
              <Text style={styles.title__item}>DEBUT</Text>
              <TextInput keyboardType={'number-pad'} value={startMeasure.toString()} onChangeText={(text) => setStartMeasure(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>CM</Text>
            </View>
            <View style={[styles.item__content, styles.sc]}>
              <Text style={styles.title__item}>1 MOIS</Text>
              <TextInput keyboardType={'number-pad'} value={oneMonthMeasure.toString()} onChangeText={(text) => setOneMonthMeasure(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>CM</Text>
            </View>
            <View style={[styles.item__content, styles.tc]}>
              <Text style={styles.title__item}>3 MOIS</Text>
              <TextInput value={threeMonthMeasure.toString()} onChangeText={(text) => setthreeMonthMeasure(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>CM</Text>
            </View>
          </View>
          <Text style={styles.summariesTitle}>MON POIDS</Text>
          <View style={styles.flexblock}>
            <View style={[styles.item__content, styles.fc]}>
              <Text style={styles.title__item}>DEBUT</Text>
              <TextInput value={startWeight.toString()} onChangeText={(text) => setStartWeight(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>KG</Text>
            </View>
            <View style={[styles.item__content, styles.sc]}>
              <Text style={styles.title__item}>1 MOIS</Text>
              <TextInput value={oneMonthWeight.toString()} onChangeText={(text) => setOneMonthWeight(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>KG</Text>
            </View>
            <View style={[styles.item__content, styles.tc]}>
              <Text style={styles.title__item}>3 MOIS</Text>
              <TextInput value={threeMonthWeight.toString()} onChangeText={(text) => setthreeMonthWeight(parseInt(text) || 0)} style={styles.input__item} />
              <Text style={styles.title__item__r}>KG</Text>
            </View>

          </View>
          <Text style={styles.summariesTitle}>MON EVOLUTION EN PHOTOS</Text>
          <View style={styles.flexblock}>
            <View style={[styles.item__content, styles.item__pic]}>
              <Text style={startPicture === '' ? styles.title__item__undefined : styles.title__item}>DEBUT</Text>
              {startPicture !== '' && <Image style={styles.img__poids} source={{ uri: startPicture }}></Image>}
              <Pressable style={styles.title__item__r_b} onPress={() => {
                ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.All,
                  allowsEditing: true, aspect: [4, 3], quality: 1,
                  base64: true
                }).then((result) => {
                  if (result.canceled) return
                  if (result.assets![0].base64) {
                    const imageUri = `data:image/png;base64,${result.assets![0].base64}`;
                    RESTUserUploadImage(token, imageUri, 'photoStart')
                      .then((user) => {
                        if (user !== undefined) {
                          dispatch(storePopulateUser(user))
                          setStartPicture(user.photoStart)
                        } else {
                          Alert.alert('Une erreur s\'est produite lors du chargement de l\'image')
                        }
                      })
                      .catch((e) => console.log(e))
                  } else {
                    Alert.alert('Erreur lors du chargement de l\'image')
                  }
                })
              }}>
                <Text style={styles.title__item__r_a}>MODIFIER</Text>
              </Pressable>
            </View>
            <View style={[styles.item__content, styles.item__pic]}>
              <Text style={oneMonthPicture === '' ? styles.title__item__undefined : styles.title__item}>1 MOIS</Text>
              {oneMonthPicture !== '' && <Image style={styles.img__poids} source={{ uri: oneMonthPicture }}></Image>}
              <Pressable style={styles.title__item__r_b} onPress={() => {
                ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.All,
                  allowsEditing: true, aspect: [4, 3], quality: 1,
                  base64: true
                }).then((result) => {
                  if (result.canceled) return
                  if (result.assets![0].base64) {
                    const imageUri = `data:image/png;base64,${result.assets![0].base64}`;
                    RESTUserUploadImage(token, imageUri, 'photoOneMonth')
                      .then((user) => {
                        if (user !== undefined) {
                          dispatch(storePopulateUser(user))
                          setOneMonthPicture(user.photoOneMonth)
                        } else {
                          Alert.alert('Une erreur s\'est produite lors du chargement de l\'image')
                        }
                      }).catch((e) => console.log(e))
                  } else {
                    Alert.alert('Erreur lors du chargement de l\'image')
                  }
                })
              }}>
                <Text style={styles.title__item__r_a}>MODIFIER</Text>
              </Pressable>
            </View>
            <View style={[styles.item__content, styles.item__pic]}>
              <Text style={threeMonthPicture === '' ? styles.title__item__undefined : styles.title__item}>3 MOIS</Text>
              {threeMonthPicture !== '' && <Image style={styles.img__poids} source={{ uri: threeMonthPicture }}></Image>}
              <Pressable style={styles.title__item__r_b} onPress={() => {
                ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.All,
                  allowsEditing: true, aspect: [4, 3], quality: 1,
                  base64: true
                }).then((result) => {
                  if (result.canceled) return
                  if (result.assets![0].base64) {
                    const imageUri = `data:image/png;base64,${result.assets![0].base64}`;
                    RESTUserUploadImage(token, imageUri, 'photoThreeMonth')
                      .then((user) => {
                        if (user !== undefined) {
                          dispatch(storePopulateUser(user))
                          setThreeMonthPicture(user.photoThreeMonth)
                        } else {
                          Alert.alert('Une erreur s\'est produite lors du chargement de l\'image')
                        }
                      }).catch((e) => console.log(e))
                  } else {
                    Alert.alert('Erreur lors du chargement de l\'image')
                  }
                })
              }}>
                <Text style={styles.title__item__r_a}>MODIFIER</Text>
              </Pressable>
            </View>
          </View>
          <View style={styles.btn__container}>
            <Pressable onPress={() => updateStats()}>
              <Text style={styles.SaveBtn}>S'ENREGISTRER</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  btn__container: {
    marginTop: 80,
    marginBottom: 20

  },
  SaveBtn: {
    color: '#fff',
    fontWeight: '500',
    fontSize: 15,
    textAlign: 'center',
    padding: 15,
    backgroundColor: 'black'
  },
  title__item__r_a: {
    position: 'absolute',
    bottom: 5,
    zIndex: 1,
    right: 5,
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 10,
    padding: 5
  },
  title__item__r_b: {
    position: 'absolute',
    bottom: 5,
    zIndex: 1,
    right: 5,
    color: '#000',
    fontSize: 10,
    padding: 5
  },
  item__pic: {
    position: 'relative',
    flex: 1,
    height: 125,
    overflow: 'hidden',

  },
  img__poids: {
    width: 200,
    height: 125,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    resizeMode: 'cover',
    position: 'absolute',
    zIndex: -1
  },
  fc: {
    backgroundColor: '#B46290'
  },
  sc: {
    backgroundColor: '#E584BB'
  },
  tc: {
    backgroundColor: '#FF8BCD'
  },
  flexblock: {
    marginTop: 25,
    marginBottm: 25,
    gap: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'

  },
  item__content: {
    flex: 1,
    padding: 15,

    width: 60,

    borderRadius: 10,
    position: 'relative',
  },
  title__item__undefined: {
    color: '#000',
    fontSize: 10
  },
  title__item: {
    color: 'white',
    fontSize: 10
  },
  title__item__r: {
    color: 'white',
    fontSize: 10,
    textAlign: 'right'
  },
  input__item: {
    color: 'white',
    fontSize: 30,
    margin: 10,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  img__icon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    width: 32,
    height: 32,

  },
  board: {
    display: 'flex',
    flexDirection: 'column'
  },
  title__board: {
    color: 'white',
    fontWeight: 'bold'
  },
  content__board: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 15,
    marginTop: 25,

    marginBottom: 15,
    width: '100%'
  },
  mood__board: {
    flex: 1,
    position: 'relative',
    padding: 15,
    borderRadius: 5,
    height: 100,
    backgroundColor: '#E584BB',
    textAlign: 'left',
    color: 'white'
  },
  abonnement__board: {
    flex: 1,
    padding: 15,
    position: 'relative',
    borderRadius: 5,
    height: 100,
    backgroundColor: '#C8639C',
    textAlign: 'left',
    color: 'white'
  },
  stats: {
    padding: 15,
    borderRadius: 5,
    height: 125,
    position: 'relative',
    backgroundColor: '#D66DA8',
    textAlign: 'left',
    color: 'white'
  },
  container: {
    height: '100%',
    width: '100%',

    paddingLeft: 20,
    paddingRight: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 30,
    gap: 20,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1
  },
  profilepicture: {
    width: 70,
    height: 70,
    borderRadius: 50,
    borderColor: '#fff',
    borderWidth: 2
  },
  headertexts: {
    display: 'flex',
    flexDirection: 'column'
  },
  textHello: {
    color: '#000',
    fontWeight: '300',
    fontSize: 24
  },
  textFullname: {
    color: '#000',
    fontWeight: '400',
    fontSize: 30
  },
  summaries: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10
  },
  summariesTitle: {
    color: '#000',
    fontSize: 14,
    paddingTop: 20,
    fontWeight: "800"
  },
  summariesData: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  summariesDataTitle: {
    color: '#000',
    fontWeight: '400',
    fontSize: 12
  },
  summariesDataData: {
    color: '#000',
    fontWeight: '500',
    fontSize: 15
  }
});
