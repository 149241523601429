import {
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IExtentedVideo } from "../../libs/Models";

interface VideosState {
  entities: IExtentedVideo[]
}
const initialState: VideosState = {
  entities: [],
};

const VideosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    addVideos(state, action: PayloadAction<IExtentedVideo[]>) {
      state.entities = action.payload
    },
    updateVideo(state, action: PayloadAction<IExtentedVideo>) {
      const toUpdate = state.entities.findIndex((obj => obj.id == action.payload.id));
      state.entities[toUpdate] = action.payload;
    }
  },
});

export const selectVideosByCategory = createSelector(
  [
    (state: VideosState) => state.entities,
    (_state: VideosState, category: string) => category
  ],
  (entities, category) => entities.filter(el => el.categories && el.categories.filter(n => n.id === category).length > 0)
);

export const selectFavoritesVideos = createSelector(
  [
    (state: VideosState) => state.entities,
    (_state: VideosState, user: string) => user
  ],
  (entities, user) => entities.filter(el => el.favorites && el.favorites.filter(n => n.id === user).length > 0)
);

export const { addVideos, updateVideo } = VideosSlice.actions; 

export default VideosSlice.reducer;