import axios from 'axios';
import { ICours } from './Models';

export const GRAPHQLGetCours = async (): Promise<ICours[]> => {
  const response = await axios({
    url: 'https://belly-sculpting-api.herokuapp.com/graphql',
    method: 'post',
    data: {
      query: `
        query getCourses {
            courses {
                id
                date
                availableSeats
                lieu
                name
                description
                price
                address
                capacity
            }
        }
        `
    }
  });

  return response.data.data.courses;
}