import { HeaderTitleProps } from "@react-navigation/elements/src/types"
import { FC } from "react"
import { Image, ImageSourcePropType, StyleSheet, Text } from "react-native";

interface Props extends HeaderTitleProps {
    title: string;
    icon?: ImageSourcePropType;
}

export const HeaderBarTitle: FC<Props> = (_props: Props) => {
    const { title, icon } = _props;

    return icon === undefined ? (
        <Text style={styles.text}>{ title === '' ? '' : title }</Text>
    ) : (
        <Image source={icon}></Image>
    )
}

const styles = StyleSheet.create({
    text: {
        fontSize: 20,
        fontWeight: '700',
        color: '#fff'
    }
})