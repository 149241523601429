import { Image, StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect } from "react";
import { Text } from '../components/Text';
import { useTypedSelector } from "../store";
import { useIsFocused } from "@react-navigation/native";
import { Link } from "@react-navigation/native"
interface Props extends NativeStackScreenProps<RootStackParamList, 'Abonnement'> { }

export const Abonnement: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
  }, [_props, isFocused])

  const getFormatedDate = (unformattedDate: string): string => {
    let date: string | string[] = unformattedDate.split('T');
        date = date[0];
        date = date.split('-');

    return `${date[date.length - 1]}/${date[date.length - 2]}/${date[date.length - 3]}`;
  }

  return (
    <View style={styles.container}>
      <View style={styles.summaries}>
        <Text style={styles.summariesTitle}>MON ABONNEMENT</Text>
        { !entity?.isSubscribed &&
          <View style={styles.box_no_sub}>
            <Text style={styles.title_no_sub}>NON ABONNÉE ?</Text>
            <Text style={styles.title_no_sub}>INSCRIVEZ-VOUS SUR BELLY-SCULPTING.COM</Text>
          </View>
        }
        <View style={styles.summariesData}>
          <Text style={styles.summariesDataTitle}>VOTRE PACK ACTUEL</Text>
          <Text style={styles.summariesDataData}>{ entity?.isSubscribed ? 'PACK BELLY SCULPTING' : 'AUCUN' }</Text>
        </View>
        { entity?.isSubscribed &&
          <>
            <View style={styles.summariesData}>
              <Text style={styles.summariesDataTitle}>DATE D’INSCRIPTION</Text>
              <Text style={styles.summariesDataData}>{ (entity !== null && entity.subscriptionDate) ? getFormatedDate(entity?.subscriptionDate?.toString()) : '' }</Text>
            </View>
            {/*
              <View style={styles.summariesData}>
                <Text style={styles.summariesDataTitle}>DATE D’EXPIRATION</Text>
                <Text style={styles.summariesDataData}>25/07/2022</Text>
              </View> 
            */}
          </>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  box_no_sub: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',

    padding: 15,
    backgroundColor: '#E584BB'
  },
  title_no_sub: {
    color: 'white',
    margin: 10,
    fontWeight: 'bold'
  },
  img__icon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    width: 32,
    height: 32,

  },
  link_title: {
    width: '100%',
    height: '100%',
    position: 'relative',


  },
  board: {
    display: 'flex',
    flexDirection: 'column'
  },
  title__board: {
    color: 'white',
    fontWeight: 'bold'
  },
  content__board: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 15,
    marginTop: 25,

    marginBottom: 15,
    width: '100%'
  },
  mood__board: {
    flex: 1,
    position: 'relative',
    padding: 15,
    borderRadius: 5,
    height: 100,
    backgroundColor: '#E584BB',
    textAlign: 'left',
    color: 'white'
  },
  abonnement__board: {
    flex: 1,
    padding: 15,
    position: 'relative',
    borderRadius: 5,
    height: 100,
    backgroundColor: '#C8639C',
    textAlign: 'left',
    color: 'white'
  },
  stats: {
    padding: 15,
    borderRadius: 5,
    height: 125,

    width: '100%',
    position: 'relative',
    backgroundColor: '#D66DA8',
    textAlign: 'left',
    color: 'white'
  },
  container: {
    height: '100%',
    width: '100%',

    paddingLeft: 20,
    paddingRight: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 30,
    gap: 20,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1
  },
  profilepicture: {
    width: 70,
    height: 70,
    borderRadius: 50,
    borderColor: '#fff',
    borderWidth: 2
  },
  headertexts: {
    display: 'flex',
    flexDirection: 'column'
  },
  textHello: {
    color: '#000',
    fontWeight: '300',
    fontSize: 24
  },
  textFullname: {
    color: '#000',
    fontWeight: '400',
    fontSize: 30
  },
  summaries: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10
  },
  summariesTitle: {
    color: '#000',
    fontSize: 20,
    paddingTop: 20,
    fontWeight: "800"
  },
  summariesData: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  summariesDataTitle: {
    color: '#000',
    fontWeight: '400',
    fontSize: 12
  },
  summariesDataData: {
    color: '#000',
    fontWeight: '900',
    fontSize: 20
  }
});
