import { Link } from '@react-navigation/native'
import { ImageBackground, StyleSheet, View } from 'react-native'
import { ICategory } from '../libs/Models'
import { Text } from './Text'

interface IProps {
    disabled: boolean
    category: ICategory
    image: any,
    title1: string,
    title2: string,
}

export const CategoryThumb = ({ category, title1, title2, image, disabled }: IProps) => {
    return (
        <View style={styles.container}>
            <Link to={ disabled ? { screen: 'Authentication' } : { screen: 'Category', params: { category } }} style={{ width: '100%' }}>
                <ImageBackground source={image} style={styles.background}>
                    <View style={ disabled ? styles.disabledLayer : styles.layer}> 
                        <Text style={disabled ? styles.disabledTitle : styles.title}>{ title1 }{"\n"}{title2}</Text>
                        <Text style={disabled ? styles.disabledDiscover : styles.discover}>DÉCOUVRIR</Text>
                    </View>
                </ImageBackground>
            </Link>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        height: 130,
        width: '100%'
    },
    background: {
        height: 130,
        width: '100%',
        alignSelf: 'stretch',
        resizeMode: 'cover',
    },
    disabledLayer: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        height: 130,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    layer: {
        height: 130,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    disabledTitle: {
        color: '#fff',
        fontSize: 20,
        fontWeight: '700',
        paddingLeft: 20,
        paddingBottom: 7,
        opacity: 0.5
    },
    title: {
        color: '#fff',
        fontSize: 20,
        fontWeight: '700',
        paddingLeft: 20,
        paddingBottom: 7,
    },
    discover: {
        color: '#fff',
        fontSize: 10,
        paddingRight: 20,
        paddingBottom: 7
    },
    disabledDiscover: {
        color: '#fff',
        fontSize: 10,
        paddingRight: 20,
        paddingBottom: 7,
        opacity: 0.5
    }
})