import { ScrollView, StyleSheet, View } from "react-native";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from "../App";
import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "../store";
import { Link, useIsFocused } from "@react-navigation/native";
import { CalendarList, DateData, LocaleConfig } from 'react-native-calendars';
import { ImageBackground } from "react-native";
import { Text } from "../components/Text";
import { IExtentedVideo, IPlanning } from "../libs/Models";
import { GRAPHQLGetPlannings } from "../libs/PlanningsQueries";

const getOrganizedDates = (plannings: IPlanning[], videos: IExtentedVideo[]) => {
  const getMondays = () => {
    var d = new Date(), month = d.getMonth(), mondays = [];
    d.setDate(1);

    while (d.getDay() !== 1) { d.setDate(d.getDate() + 1); }
    while (d.getMonth() === month || d.getMonth() === month - 1) {
      mondays.push(new Date(d.getTime()).toISOString().split('T')[0]);
      d.setDate(d.getDate() + 7);
    }

    return mondays;
  }


  const addOneDayToDate = (dateStr: string) => {
    const date = new Date(dateStr)
    date.setTime(date.getTime() + 5 * 60 * 60 * 1000);
    date.setDate(date.getDate() + 1)

    return date.toISOString().split('T')[0]
  }

  const mondays = getMondays()

  const organized = mondays.map((monday: string, index: number) => {
    const one = monday;
    const two = addOneDayToDate(one);
    const three = addOneDayToDate(two);
    const four = addOneDayToDate(three);
    const five = addOneDayToDate(four);
    const six = addOneDayToDate(five);
    const seven = addOneDayToDate(six);

    const planning = plannings.find((planning) => planning.weekNumber === index + 1);

    const activityOne = planning?.days.find((day) => day.dayNumber === 1)
    const activityTwo = planning?.days.find((day) => day.dayNumber === 2)
    const activityThree = planning?.days.find((day) => day.dayNumber === 3)
    const activityFour = planning?.days.find((day) => day.dayNumber === 4)
    const activityFive = planning?.days.find((day) => day.dayNumber === 5)
    const activitySix = planning?.days.find((day) => day.dayNumber === 6)
    const activitySeven = planning?.days.find((day) => day.dayNumber === 7)

    return [
      { day: one, activities: activityOne?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: two, activities: activityTwo?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: three, activities: activityThree?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: four, activities: activityFour?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: five, activities: activityFive?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: six, activities: activitySix?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
      { day: seven, activities: activitySeven?.activities.map((activity) => { return { ...activity, video: videos.find((video) => activity.videoId === video.videoId) } }) },
    ]
  })

  return {
    firstDate: organized[0][0].day,
    activities: organized.flat(1)
  }
}

LocaleConfig.locales['fr'] = {
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
};

LocaleConfig.defaultLocale = 'fr';

interface Props extends NativeStackScreenProps<RootStackParamList, 'Planning'> { }

export const Planning: FC<Props> = (_props: Props) => {
  const { navigation } = _props;
  const isFocused = useIsFocused();

  let authentication = useTypedSelector((state) => state.authentication);
  const { authenticated, entity } = authentication;

  const [plannings, setPlannings] = useState<IPlanning[]>([])
  const [selectedDay, setSelectedDay] = useState<string>(new Date().toISOString().split('T')[0])

  useEffect(() => {
    !authenticated && navigation.navigate("Authentication");
    (entity && !entity.isSubscribed) && navigation.navigate("Home");

    GRAPHQLGetPlannings().then((plannings) => {
      if (plannings !== undefined) {
        plannings = plannings.filter((value) => value.packTag === 'Belly Sculpting');
        setPlannings(plannings)
      }
    })
  }, [_props, isFocused])

  const videos = useTypedSelector((state) => state.videos.entities);
  let { firstDate, activities } = getOrganizedDates(plannings, videos);

  let markedDates: any = {};
  activities.forEach((activity) => {
    const marked = activity.activities?.some((t) => (t.video !== undefined || t.title !== ''))
    markedDates[activity.day] = { selected: true, marked, selectedColor: 'rose', color: 'white' }
  })

  return (
    <ScrollView contentContainerStyle={styles.container} showsHorizontalScrollIndicator={false}>
      <View style={bannerStyles.container}>
        <ImageBackground source={require('../assets/courses-background.png')} style={bannerStyles.background}>
          <Text style={bannerStyles.title}>TOUS VOS COURS EN LIGNE et EN STUDIO DISPONIBLES SUR VOTRE AGENDA.</Text>
          <Text style={bannerStyles.info}>NOUVELLE VIDÉO HEBDOMADAIRE TOUS LES LUNDIS</Text>
        </ImageBackground>
      </View>
      <View style={styles.container}>
        <CalendarList
          markedDates={markedDates}
          current={new Date().toISOString().split('T')[0]}
          minDate={firstDate}
          pastScrollRange={0}
          futureScrollRange={0}
          onDayPress={(date: DateData) => setSelectedDay(date.dateString)}
          hideArrows={false}
          enableSwipeMonths={true}
          horizontal={true}
          pagingEnabled={true}
          theme={{
            backgroundColor: '#ffffff',
            calendarBackground: '#ffffff',
            textSectionTitleColor: '#000',
            textSectionTitleDisabledColor: '#d9e1e8',
            // selectedDayBackgroundColor: '#00adf5',
            selectedDayTextColor: '#E584BB',
            selectedDayBackgroundColor: '#E584BB',
            todayTextColor: '#E584BB',
            dayTextColor: '#2d4150',
            textDisabledColor: '#d9e1e8',
            dotColor: '#00adf5',
            selectedDotColor: '#000',
            arrowColor: '#000',
            disabledArrowColor: '#000',
            monthTextColor: 'black',
            indicatorColor: 'yellow',

            textDayFontWeight: '300',
            textMonthFontWeight: 'bold',
            textDayHeaderFontWeight: '300',
            textDayFontSize: 16,
            textMonthFontSize: 16,
            textDayHeaderFontSize: 16
          }}
        />
        <View>
          {activities.map((activity, key) => activity.day === selectedDay && (
            <View key={key} style={styles.list_box}>
              {activity.activities?.map((planning, key) => {
                if (planning.title !== '') {
                  return planning.video !== undefined ? (
                    <Link key={key} to={{ screen: 'Video', params: { video: planning.video } }} style={styles.box_planning_video}>
                      <View style={styles.box_planning_video_content}>
                        <Text style={styles.text__planning}>{planning.title}</Text>
                        <Text style={styles.span__planning}>{planning.video?.categories[0].name}</Text>
                      </View>
                    </Link>
                  ) : (
                    <View key={key} style={styles.box_planning_video}>
                      <Text style={styles.text__planning}>{planning.title}</Text>
                    </View>
                  )
                }
              })}
            </View>
          ))}
        </View></View>
    </ScrollView>
  )
}
const bannerStyles = StyleSheet.create({
  box_all: {
    width: '100%',
    display: 'flex'
  },
  container: {
    height: 350,
    width: '100%'
  },
  background: {
    flex: 1,
    resizeMode: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 70,
    gap: 30
  },
  title: {
    color: '#fff',
    fontSize: 17,
    fontWeight: '700',
    zIndex: 9,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  info: {
    color: 'white',
    textAlign: 'center',
  },
  name: {
    color: '#fff',
    fontSize: 22,
    fontWeight: '700'
  },
  date: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '400'
  },
})

const styles = StyleSheet.create({
  list_box: {
    display: 'flex',
    marginLeft: 15,
    marginBottom: 20,
    marginRight: 15,

    flexDirection: 'column'
  },
  box_planning_video_content: {
    marginBottom: 20,
    padding: 18,

  },
  box_planning_video: {

    height: 130,
    marginBottom: 20,
    padding: 18,

    backgroundColor: '#E584BB',
  },
  box_planning_cours: {
    marginLeft: 10,
    marginRight: 10,
    padding: 15,
    paddingBottom: 50,
    backgroundColor: '#252525',
  },
  text__planning: {
    color: 'white',
    fontSize: 22,
  },
  span__planning: {
    color: 'white',
    fontSize: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    marginBottom: 40,
    minheight: '100%',
    backgroundColor: '#fff',
    gap: 25
  },

  description: {
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
  },

});
